export { debounce } from 'lodash';

export { fetchPossibleProcedures } from 'app/actions/episodeActions';

/**
 * Return an object that maps search suggestions to a list of offered
 * procedures that match that suggestion.
 *
 * @param   {object}  possibleProcedures  A list of offered procedures available to this patient.
 * @param   {string}  search              A search query to filter suggestions by.
 * @return  {object}                      An object mapping search suggestions to procedures.
 */
export const getProcedureSearchSuggestionMapping = (
  possibleProcedures,
  search
) => {
  const mapping = {};

  possibleProcedures.forEach((procedure) => {
    procedure.tags
      .map((tag) => tag.toLowerCase())
      .filter((tag) => tag.includes(search.toLowerCase()))
      .forEach((tag) => {
        mapping[tag] = mapping[tag] || [];
        mapping[tag].push(procedure);
      });
  });

  return mapping;
};

/**
 * Take the search suggestion mappings and return a flat array of search
 * suggestions, sorted and limited based on business requirements.
 *
 * @returns An array of tuples: [procedure description, procedure]
 *
 * @example
 * [
 *   ['knee replacement', { ... }],
 *   ['knee pain', { ... }],
 *   ['knee revision', { ... }],
 * ]
 */
export const getSuggestionsFromMapping = (mapping, search) => {
  return Object.entries(mapping).sort((entry) =>
    entry[0].startsWith(search) ? -1 : 1
  );
};

/**
 * Get the subset of welcome page data that is associated with the client
 * type.
 *
 * @param    {array}   pages       the full set of welcome pages
 * @param    {string}  clientType  the type of client ("employer" or "partner")
 * @returns  {array}               the filtered subset of welcome pages
 */
export const getWelcomePagesForClientType = (pages, clientType) =>
  pages.filter((page) => page.clientTypes.includes(clientType));

export { isDownKey, isEnterKey, isUpKey } from 'app/util/keyboardUtils';

export { logEvent } from 'app/util/analytics';

export { receiveSetCallUsModalVisibility } from 'app/actions/uiActions';

export { routeToFAQ } from 'app/util/routeUtils';
