import { removeAttachment } from 'app/components/Messages/helpers';

import {
  MessageAttachmentsContainer,
  MessageAttachmentsFilePreview,
  MessageAttachmentsIcon,
  MessageAttachmentsScrollView,
} from 'app/components/Messages/styles';

import { MessageAttachmentsProps } from 'app/components/Messages/types';

/** Render previews of pending message attachments. */
const MessageAttachments = ({
  attachments,
  formValues,
  isRendered = false,
  setFormValues,
}: MessageAttachmentsProps) => {
  if (attachments?.length === 0) return null;
  if (!isRendered) return null;

  return (
    <MessageAttachmentsContainer>
      <MessageAttachmentsScrollView>
        {attachments.map((attachment, index) => (
          // @ts-expect-error no overload matches
          <MessageAttachmentsFilePreview
            key={index}
            file={attachment}
            controls={false}
          >
            <MessageAttachmentsIcon
              name="remove"
              onPress={() => removeAttachment(index, formValues, setFormValues)}
            />
          </MessageAttachmentsFilePreview>
        ))}
      </MessageAttachmentsScrollView>
    </MessageAttachmentsContainer>
  );
};

export default MessageAttachments;
