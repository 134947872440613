import {
  MessageLoadMoreButton,
  MessageLoadMoreContainer,
} from 'app/components/Messages/styles';

import { MessageLoadMoreProps } from 'app/components/Messages/types';

/**
 * Render a "Load Previous Messages" button on web browsers.
 *
 * @param  loading  whether to disable the button with a spinner
 * @param  onPress  function to call when pressing the button
 */
const MessageLoadMore = ({
  loading = false,
  onPress,
}: MessageLoadMoreProps) => {
  return (
    <MessageLoadMoreContainer>
      <MessageLoadMoreButton
        title="Load Previous Messages"
        disabled={loading}
        loading={loading}
        onPress={onPress}
      />
    </MessageLoadMoreContainer>
  );
};

export default MessageLoadMore;
