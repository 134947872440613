import React, { ReactNode, useEffect } from 'react';
import {
  StyleProp,
  StyleSheet,
  useWindowDimensions,
  ViewStyle,
} from 'react-native';

import { isEmpty } from 'lodash';
import { Overlay } from 'react-native-elements';

import { DotIndicator } from 'app/components/Common/svg-components';

import { playSound } from 'app/util/soundUtils';
import theme from 'app/util/theme';
import { TestID } from 'app/util/test-id';

import { getPadding, getWidth } from './helpers';
import {
  ButtonsWrapper,
  CloseIcon,
  CloseIconButton,
  ContentWrapper,
  ContentScrollWrapper,
  DisclaimerText,
  ModalCTAButton,
  Title,
} from './styles';
import { ModalOption, ModalProps, ModalSize } from './types';
export { ModalOption, ModalProps } from './types';

export const Modal = ({
  backgroundColor = 'sand',
  children,
  contentStyleOverrides = {},
  disclaimer = null,
  fullScreen = false,
  onClose,
  options = [],
  overlayStyleOverrides = {},
  scrollable = true,
  showCloseX = true,
  size = 'small',
  sound = '',
  testID = TestID.Modal.Component,
  title = '',
  ...overlayProps
}: ModalProps) => {
  useEffect(() => {
    if (!overlayProps.isVisible || !sound) return;

    playSound(sound);
  }, [overlayProps.isVisible, sound]);

  const ContentWrapperComponent = scrollable
    ? ContentScrollWrapper
    : ContentWrapper;

  const stackedButtons = options.length > 1;

  const { height: screenHeight, width: screenWidth } = useWindowDimensions();

  const responsiveOverlayStyles = {
    justifyContent: 'space-between',
    padding: getPadding(size, screenWidth),
    width: getWidth(size, screenWidth),
  } as Record<string, unknown>;

  // If the screen is mobile-sized, then the min-height should be set to near-full screen.
  if (screenWidth < theme.breakpoints.small) {
    responsiveOverlayStyles.maxHeight = screenHeight - theme.navigationHeight;
    responsiveOverlayStyles.minHeight =
      screenHeight - theme.navigationHeight - theme.spacing * 0.75;
  }

  // The background color of the modal defaults to sand but can be overridden (typically to 'white').
  if (backgroundColor) {
    responsiveOverlayStyles.backgroundColor = theme.colors[backgroundColor];
  }

  if (fullScreen) {
    responsiveOverlayStyles.width = screenWidth;
    responsiveOverlayStyles.height = screenHeight;
    responsiveOverlayStyles.padding = 0;
  }

  return (
    <Overlay
      onBackdropPress={onClose}
      overlayStyle={StyleSheet.flatten([
        responsiveOverlayStyles,
        overlayStyleOverrides,
      ])}
      {...overlayProps}
    >
      <ContentWrapperComponent style={contentStyleOverrides} testID={testID}>
        {!!title && <Title>{title}</Title>}

        {!isEmpty(children) && children}
      </ContentWrapperComponent>

      {!isEmpty(options) && (
        <ButtonsWrapper
          hasMarginTop={!isEmpty(children)}
          stackedButtons={stackedButtons}
        >
          {options.map((option, index) => (
            <ModalCTAButton
              disabled={option.isLoading}
              icon={
                option.isLoading && (
                  <DotIndicator
                    color={option.loadingColor || theme.colors.white}
                  />
                )
              }
              keepCasing={option.keepCasing}
              key={index}
              stackButtons={stackedButtons}
              testID={TestID.Modal.CloseButton}
              title={option.isLoading ? '' : option.title}
              type={option.type ? option.type : 'outline'}
              {...option}
            />
          ))}
        </ButtonsWrapper>
      )}

      {!!disclaimer && (
        <DisclaimerText align="center">{disclaimer}</DisclaimerText>
      )}

      {/* The CloseIconButton can be hidden with the `showCloseX` flag, but it should show if there are no option CTAs.
      This prevents the modal from being impossible to close, especially when the modal is full screen on a mobile device */}
      {(isEmpty(options) || showCloseX) && (
        <CloseIconButton
          modalSize={size}
          onPress={onClose}
          testID={TestID.Modal.CloseIcon}
        >
          <CloseIcon />
        </CloseIconButton>
      )}
    </Overlay>
  );
};

export default Modal;
