import { Platform, ViewProps } from 'react-native';

import { ThemeProvider as ThemeProviderRaw } from 'react-native-elements';
import styled, { css } from 'styled-components/native';

import { View } from 'app/components/Common/Styled';
import {
  HomeLeft as HomeLeftRaw,
  HomeRight as HomeRightRaw,
} from 'app/components/Common/svg-components';
import theme from 'app/util/theme';

const isMediumOrLargerDevice = ({ breakpoints, windowDimensions }) =>
  windowDimensions.width > breakpoints.small;

interface SvgWrapperProps extends ViewProps {
  side: 'left' | 'right';
}

export const Container = styled(View)`
  background-color: ${theme.colors.sand};
  flex: 1 0 auto;
  flex-direction: row;
  justify-content: center;
  padding-top: 0;
`;

export const HomeLeft = HomeLeftRaw;

export const HomeRight = HomeRightRaw;

export const MainWrapper = styled(View)`
  max-width: 650px;
  flex-direction: column;
  justify-content: space-between;

  ${({ theme }) =>
    isMediumOrLargerDevice(theme)
      ? css`
          flex: 1 0 450px;
          padding-horizontal: ${({ theme }) => theme.spacing * 4}px;
        `
      : css`
          flex: 1 1 auto;
        `}
`;

export const SvgWrapper = styled(View)<SvgWrapperProps>`
  flex: 1 1 auto;
  justify-content: flex-end;
`;

// Remove container styles from Input
const themeOverride = {
  ...theme,
  Input: {
    ...theme.Input,
    containerStyle: { backgroundColor: '#fff' },
    errorStyle: { margin: 0 },
    inputStyle: {
      backgroundColor: '#fff',
      fontSize: theme.fontSize,
      ...Platform.select({
        web: {
          outline: 'none',
        },
      }),
    },
  },
};

export const ThemeProvider = styled(ThemeProviderRaw).attrs({
  theme: themeOverride,
})``;
