import {
  ModalPage,
  Platform,
  REQUEST_RECEIVED_TEXT,
  TestID,
} from 'app/components/App/Navigation/constants';

import {
  callCarrum,
  createEscalation,
  getUserPhoneNumber,
} from 'app/components/App/Navigation/helpers';

import {
  useDispatch,
  useParams,
  useSelector,
  useState,
} from 'app/components/App/Navigation/hooks';

import { GetHelpModalStyles as Styled } from 'app/components/App/Navigation/styles';

import {
  GetHelpModalProps,
  MatchParams,
  ModalOption,
  State,
} from 'app/components/App/Navigation/types';

export const GetHelpModal = ({ visible, ...props }: GetHelpModalProps) => {
  const dispatch = useDispatch();
  const customPhoneNumber = useState('')[0];
  const [scheduledTime, setScheduledTime] = useState('');
  const [currentModalPage, setCurrentModalPage] = useState(
    ModalPage.CallUsPage
  );
  const [isCreatingEscalation, setIsCreatingEscalation] = useState(false);

  const carrumId = useParams<MatchParams>().carrumId;

  const userPhoneNumber = useSelector((state: State.RootState) =>
    getUserPhoneNumber(state.session)
  );

  const [phoneNumber, setPhoneNumber] = useState<string>(
    customPhoneNumber || userPhoneNumber
  );

  const onClose = () => {
    setCurrentModalPage(ModalPage.CallUsPage);
    props.onClose();
  };

  const sendEscalation = async () => {
    setIsCreatingEscalation(true);

    const success = dispatch(
      // Raise escalation with one of two types, depending on whether the carrumId was
      // available as a path fragment from the url: if it was available, pass that
      // along as the sole property of the eligible patient; otherwise, pass an empty
      // object and the patient will be pulled from the store.
      createEscalation({
        escalationType: !carrumId
          ? 'call_me_provider_search'
          : 'phone_outreach',
        exceptionStatus: 'not_applicable',
        exceptionType: null,
        description: `Patient requested call back\n- number: ${phoneNumber}\n- time: ${
          scheduledTime ? scheduledTime : 'not specified'
        }`,
        eligiblePatient: !carrumId ? {} : { carrumId },
      })
    );
    setIsCreatingEscalation(false);

    if (success) {
      setCurrentModalPage(ModalPage.RequestReceivedPage);
    }
  };

  let modalOptions: ModalOption[] = [];

  switch (currentModalPage) {
    case ModalPage.CallUsPage:
      modalOptions = [
        {
          isLoading: isCreatingEscalation,
          keepCasing: true,
          onPress: sendEscalation,
          testID: TestID.GetHelpModal.OkButton,
          title: 'OK',
          type: 'solid',
        },
      ];

      break;

    case ModalPage.RequestReceivedPage:
      modalOptions = [
        {
          onPress: onClose,
          testID: TestID.GetHelpModal.OkCloseButton,
          title: 'OK',
          type: 'solid',
        },
      ];

      break;
  }

  const renderModalContent = () => {
    switch (currentModalPage) {
      case ModalPage.CallUsPage:
        return (
          <>
            <Styled.Header>
              Call{' '}
              {Platform.OS === 'web' ? (
                <Styled.Header>1-888-855-7806</Styled.Header>
              ) : (
                <Styled.Anchor
                  onPress={(e) => callCarrum(e)}
                  testID={TestID.GetHelpModal.CarrumPhoneLink}
                  title="1-888-855-7806"
                />
              )}
            </Styled.Header>

            <Styled.DetailsText>
              Monday - Friday | 6am - 5pm PST, except major holidays
            </Styled.DetailsText>

            <Styled.DetailsText>
              For medical emergencies,{' '}
              <Styled.DetailsTextBold>please call 911.</Styled.DetailsTextBold>
            </Styled.DetailsText>

            <Styled.Divider />

            <Styled.CallYouText>or We can call you</Styled.CallYouText>

            <Styled.Text>Which number should we call?</Styled.Text>

            <Styled.PhoneInput
              input={{
                onChange: setPhoneNumber,
                value: phoneNumber?.toString() || '',
                name: TestID.GetHelpModal.PhoneInput,
              }}
              inputMode="numeric"
              enterKeyHint="done"
            />

            <Styled.Text>When should we call?</Styled.Text>

            <Styled.Subtext>
              We will try to call you at the requested time in the next 24
              hours.
            </Styled.Subtext>

            <Styled.TextArea
              input={{
                onChange: setScheduledTime,
                value: scheduledTime,
                name: TestID.GetHelpModal.TextArea,
              }}
            />
          </>
        );

      default:
        return (
          <>
            <Styled.GreenCheckMark />
            <Styled.Confirmation.Header>
              {REQUEST_RECEIVED_TEXT}
            </Styled.Confirmation.Header>
            <Styled.Confirmation.Body>
              We'll call you within 1 business day. We respond Monday-Friday
              between 6am-5pm PST, except major holidays.
            </Styled.Confirmation.Body>
          </>
        );
    }
  };

  return (
    <Styled.Modal
      isVisible={visible}
      onClose={onClose}
      options={modalOptions}
      size="small"
    >
      {renderModalContent()}
    </Styled.Modal>
  );
};
