import { EligiblePatient, EligiblePatientDTO } from './eligible-patient';
import { Address, Location } from './location';
import { Procedure } from './procedure';
import { User, UserDTO } from './user';

export namespace Episode {
  interface CareConcierge {
    id: string;
    name: string;
    profileImage?: any;
  }

  interface Closure {}

  interface Contact {
    email: string;
    name: string;
    phone: Phone;
  }

  export enum ContactMethod {
    Email = 'email',
    Phone = 'phone',
  }

  export enum BundlePriceFilter {
    Facility = 'hospital',
    Billing = 'billing',
    Zip = 'zip',
  }

  interface CostEstimate {
    carrumCoinsuranceAmount: number;
    carrumCopay: number;
    carrumDeductible: number;
    nonCarrumCoinsuranceAmount: number;
    nonCarrumCopay: number;
    nonCarrumDeductible: number;
  }

  export interface CreateEpisodePayload {
    confirmed: boolean;
    eligible_patient: EligiblePatientDTO;
    inquiry_method: string;
    location: Location;
    member_full_ssn: string;
    oop_estimate: OopEstimateDTO;
    procedure_key: string;
    status: string;
    subscriber_full_ssn: string;
    user: UserDTO;
    verified: boolean;
  }

  interface CurrentStep {
    agingThreshold: number;
    complete: boolean;
    completedDate?: any;
    createdAt: string;
    episodeId: number;
    helpContent?: any;
    id: number;
    idleThreshold: number;
    journeyPhaseId: number;
    order: number;
    presentationMode: 'workflow' | 'dashboard';
    priority: number;
    startedDate: string;
    title: string;
    updatedAt: string;
  }

  export interface ProductOffering {
    active: boolean;
    code: string;
    id: number;
    name: string;
    platform: {
      code: string;
    };
  }

  export interface Episode {
    age: number;
    billingCode?: any;
    bundlePrices?: any;
    careConcierge: CareConcierge;
    clinicalContact?: any;
    closure: Closure;
    closureDate?: any;
    closureReason?: any;
    confirmed: boolean;
    consultDate?: any;
    consultRemote: boolean;
    contactMethodPreference?: any;
    contactTimePreference?: any;
    createdAt: string;
    currentStep: CurrentStep;
    dischargeDate?: any;
    drgCode?: any;
    eligiblePatient: EligiblePatient;
    employmentStatus?: any;
    followUpDate?: any;
    id: string;
    idleRatio: number;
    inactiveReason?: any;
    initialContactDate: string;
    inquiryMethod: string;
    lastWorkedOn: string;
    location: Location;
    memberFullSsn: string;
    oopEstimate: OopEstimate;
    operationDate?: any;
    pcpFollowUpDate?: any;
    physicalTherapyDate?: any;
    physician?: any;
    priority: number;
    procedureKey: string;
    productOffering?: ProductOffering;
    provider?: any;
    referralDate?: any;
    referralSource?: any;
    relativeAge: number;
    scheduledAppointment?: any;
    status: string;
    subscriberFullSsn: string;
    travel: Closure;
    travelClearanceDate?: any;
    updatedAt: string;
    user: User;
    verified: boolean;
  }

  export interface EpisodeState {
    costEstimate: CostEstimate;
    costEstimateLoading: boolean;
    distance?: any;
    episode: Episode;
    inquiry?: any;
    loading: boolean;
    oopEstimate: OopEstimate;
    physicianRating: Closure;
    possibleHospitals: PossibleHospital[];
    possibleHospitalsPage: number;
    possibleHospitalsNextPage?: number;
    possibleHospitalsTotal: number;
    possiblePhysicians: PossiblePhysician[];
    possiblePhysiciansPage: number;
    possiblePhysiciansNextPage?: number;
    possiblePhysiciansTotal: number;
    possibleProcedures: Procedure.OfferedProcedure[];
    productOfferingsList: ProductOffering[];
    procedure: Procedure.Procedure;
    referralSources: any[];
    steps: Step[];
    journeyPhases: any;
    tempCoeSelection: {
      doctor: any;
      facility: any;
      productOffering: ProductOffering;
    };
    hospitalBundlePrices: BundlePrices[];
    hospitalBundlePricesLoading: boolean;
    estimatesError?: null | 'oop' | 'contractedRate' | 'both';
    bundlePrices: HospitalBundlePrices[];
    bundlePricesLoading: boolean;
    receiveBundlePricesError: any;
  }

  export interface BundlePrices {
    active: boolean;
    bundleId: string;
    contractedBillingCodes: string[];
    effectiveDate: string;
    id: string;
    modifier?: any;
    notes?: string;
    price: number;
    singleCaseAgreement: boolean;
    terminationDate?: string;
    warrantyLength: number;
  }

  export interface Hospital {
    accountManagerId: number;
    active: boolean;
    address: Address;
    billingCodes: any[];
    bundlePrices: any[];
    consultPrices: any[];
    contacts: Contact[];
    content: any;
    contract: Closure;
    createdAt: string;
    dashboardUrl?: any;
    documents: any[];
    id: number;
    images: Images;
    latitude: string;
    longitude: string;
    name: string;
    npi?: any;
    productOffering: ProductOffering;
    rollups: any[];
    serviceRadius: Closure;
    status: Status;
    taxId?: any;
    updatedAt: string;
    videos: any[];
    vizient: any[];
  }

  interface Images {
    logo: string;
    logoIcon: string;
    mainImage: string;
  }

  export interface Inquiry {
    closureDate?: any;
    confirmed: boolean;
    contactMethodPreference?: ContactMethod;
    contactTimePreference?: any;
    createdAt: string;
    description: string;
    eligiblePatient: EligiblePatient;
    id: string;
    initialContactDate: string;
    lastWorkedOn: string;
    location: Location;
    memberFullSsn?: any;
    procedureName: string;
    status: string;
    subscriberFullSsn?: any;
    updatedAt: string;
    user: User;
  }

  interface Meta {
    details?: string;
    event?: string;
    form?: string;
    help?: string;
    path?: string;
  }

  export interface OfferedProcedure extends Procedure.OfferedProcedure {}

  interface OopEstimate {
    coinsurance: number;
    copay: number;
    deductible: number;
    estimatedAt: string;
    finalAmount?: any;
    paid: boolean;
    savings: number;
    travel: number;
  }

  interface OopEstimateDTO {
    coinsurance: number;
    copay: number;
    deductible: number;
    estimated_at: string;
    final_amount?: any;
    paid: boolean;
    savings: number;
    travel: number;
  }

  interface Phone {
    office: string;
  }

  export interface PossibleHospital {
    address: Address;
    id: string;
    latitude: string;
    longitude: string;
    images: Images;
    name: string;
    productOffering?: ProductOffering;
    productOfferingId?: number;
    videos: any[];
  }

  export interface PossiblePhysician {
    active: boolean;
    affiliations?: any;
    availabilityInWeeks: number;
    certifications: string[];
    contactInfo: Closure;
    details: string;
    education: string[];
    fellowships?: string[];
    highAvailability: boolean;
    hospital: Hospital;
    hospitals: Hospital[];
    id: string;
    internships?: string[];
    lastName: string;
    name: string;
    profileImage: string;
    rating?: Rating;
    residencies: string[];
    specialties?: any;
    title?: any;
    videos: any[];
    yearsOfExperience?: string;
  }

  interface Rating {
    count: number;
    stars: number;
  }

  interface Status {
    availability: string;
    details: string;
  }

  export interface Step {
    complete: boolean;
    completedDate?: any;
    helpContent?: string;
    id: string;
    order: number;
    presentationMode: 'workflow' | 'dashboard';
    journeyPhaseId: number | string;
    startedDate?: string;
    tasks: Task[];
    title: string;
  }

  export interface Task {
    complete: boolean;
    createdAt: string;
    id: number;
    keyType: string;
    meta: Meta;
    stepId: number;
    taskType: string;
    title: string;
    updatedAt: string;
  }

  export interface CreateEpisodePayload {
    location: Location;
    oop_estimate: OopEstimateDTO;
    eligible_patient: EligiblePatientDTO;
    member_full_ssn: string;
    subscriber_full_ssn: string;
    procedure_key: string;
    user: UserDTO;
    inquiry_method: string;
    status: string;
    confirmed: boolean;
    verified: boolean;
  }

  export interface HospitalBundlePrices {
    bundle_prices: BundlePriceFilter;
    billing_code: number;
    hospital: string;
    price: number;
    zip_code: number;
  }
}
