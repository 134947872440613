import {
  DotIndicator,
  Notification,
  NotificationContainer,
} from 'app/components/Messages/styles';

/**
 * Render the default content when there are no messages.
 *
 * @param  isLoading  whether conversation is loading
 */
export const MessageListEmpty = ({
  isLoading = true,
}: {
  isLoading?: boolean;
}) => {
  if (isLoading) {
    return (
      <NotificationContainer>
        <Notification>Loading messages...</Notification>
        <DotIndicator />
      </NotificationContainer>
    );
  }

  return <Notification>You have no messages</Notification>;
};
