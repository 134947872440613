import styled, { css } from 'styled-components/native';
import { DefaultTheme } from 'types/theme';

import {
  AnchorProps,
  AttachmentProps,
  AuthorshipDependentView,
  MessagesHeaderUserStatusProps,
  MessageTextProps,
  MessageTextWrapperProps,
} from './types';

import Autolink from 'react-native-autolink';
import { Button } from 'react-native-elements';
import FilePreview from 'app/components/Common/FilePreview';
import { FlatList } from 'react-native';
import { KeyboardAvoidingView } from 'react-native';
import * as Linking from 'expo-linking';
import { Platform } from 'react-native';
import { ResponsivePressable } from 'app/components/Common/ResponsivePressable';
import { ScrollView } from 'app/components/Common/Styled';
import { Text } from 'react-native-elements';
import { TextArea } from 'app/components/Common/TextArea';
import TrackableButton from 'app/components/Common/TrackableButton';
import { View } from 'app/components/Common/Styled';
import { Button as ButtonRaw, ButtonProps, Icon } from 'react-native-elements';

const getBackgroundColorForMessagesHeaderUserStatus = (status, theme) => {
  if (status === 'online') return theme.colors.brightGreen;
  if (status === 'idle') return theme.colors.yellow;
  return theme.colors.mediumGrey;
};

/** The size of the message actions that this component hides behind. */
const getFooterHeight = (theme) =>
  Platform.select({
    default: theme.fontSizes.body3 * 11.5,
    web: theme.fontSizes.body3 * 12.5,
  });

const ListContainerComponent = Platform.select({
  web: View,
  // @ts-expect-error No overload matches this call.
  default: KeyboardAvoidingView,
});

const getSendButtonStyle = (theme: DefaultTheme) => ({
  paddingBottom: theme.spacing / 2,
  paddingLeft: theme.spacing * 2,
  paddingRight: theme.spacing * 2,
  paddingTop: theme.spacing / 2,
  marginLeft: theme.isNarrow ? 0 : theme.spacing,
  marginRight: theme.isNarrow ? theme.spacing : 0,
});

const getSendButtonContainerStyle = (
  onlyNarrow: boolean,
  onlyWide: boolean,
  theme: DefaultTheme
) => ({
  justifyContent: 'center',
  display:
    (onlyNarrow && !theme.isNarrow) || (onlyWide && theme.isNarrow)
      ? 'none'
      : 'initial',
});

interface SendButtonProps extends ButtonProps {
  onlyNarrow?: boolean;
  onlyWide?: boolean;
  theme: DefaultTheme;
}

const SendButton = styled(Button).attrs(
  ({ onlyNarrow, onlyWide, onPress, theme }: SendButtonProps) => ({
    backgroundColor: onPress ? '#1166FF' : '#93a5c7',
    borderRadius: 4,
    buttonStyle: getSendButtonStyle(theme),
    containerStyle: getSendButtonContainerStyle(onlyNarrow, onlyWide, theme),
    fontFamily: theme.fontFamilyBold,
    fontSize: theme.fontSizes.body2,
  })
)<SendButtonProps>``;

const MessageContainer = styled(View)`
  ${({ theme }) => css`
    border-radius: ${theme.spacing / 2}px;
    flex: 1 0 auto;
    flex-wrap: wrap;
    margin-bottom: ${theme.spacing / 2}px;
    max-width: ${theme.isNarrow
      ? `${theme.windowDimensions.width - 32 - theme.spacing * 1.25}px`
      : `${theme.breakpoints.small}px`};
    padding: ${theme.spacing / 2}px;
  `}
`;

export const Anchor = styled(Autolink).attrs<AnchorProps, any>(
  ({ patientIsAuthor, theme }) => ({
    linkStyle: {
      textDecorationLine: 'underline',
      ...(patientIsAuthor && {
        color: theme.colors.white,
        fontFamily: theme.fontFamilyMedium,
      }),
    },
  })
)``;

export const AttachmentPreview = styled(FilePreview).attrs<
  AttachmentProps,
  any
>(({ patientIsAuthor, theme }) => ({
  containerStyle: {
    alignItems: patientIsAuthor ? 'flex-end' : 'flex-start',
    alignSelf: patientIsAuthor ? 'flex-end' : 'flex-start',
    marginBottom: theme.spacing / 2,
  },
}))`
  ${({ theme }) => css`
    icon-padding: ${theme.spacing * 2}px;
    max-height: ${theme.spacing * 12.5}px;
  `}
`;

export const AuthorName = styled(Text)`
  ${({ theme }) => css`
    color: ${theme.colors.darkGrey};
    font-family: ${theme.fontFamilyMedium};
    font-size: ${theme.fontSizes.body2}px;
    margin-right: ${theme.spacing / 2}px;
  `}
`;

export { default as CallCarrumLink } from 'app/components/Common/Anchor';

export const Container = styled(View)`
  ${({ theme }) => css`
    flex: 1 0 auto;
    background-color: ${theme.colors.white};
  `}
`;

export const DeliveredIcon = styled(Icon).attrs(({ theme }) => ({
  color: theme.colors.mediumGrey,
  name: 'check',
  size: theme.spacing * 1.5,
  type: 'evilicon',
}))``;

export { DotIndicator } from 'app/components/Common/svg-components';

export { default as Footer } from './Footer';

export const Holiday = styled(ButtonRaw).attrs<ButtonProps>(({ theme }) => {
  const isSmallScreen = theme.windowDimensions.width < theme.breakpoints.large;
  return {
    buttonStyle: { padding: isSmallScreen ? 0 : theme.spacing / 2 },
    icon: <Icon name="info" color={theme.colors.primary} />,
    title: 'Holiday Notice',
    titleStyle: {
      marginLeft: theme.spacing / 2,
    },
    type: 'clear',
  };
})<ButtonProps>`
  ${({ theme }) => css`
    margin-right: ${theme.spacing / 2}px;
    padding-left: ${theme.spacing}px;
  `}
`;

export const IconWrapper = styled(View)`
  ${({ theme }) => css`
    bottom: -${theme.spacing}px;
    position: absolute;
    right: -1px;
  `}
`;

export { Linking };

export const ListContainer = styled(View)`
  ${({ theme }) => css`
    flex: 1 0 auto;
  `}
`;

export { default as Message } from 'app/components/Messages/MessageGroup/Message';

export { default as MessageAttachments } from './MessageAttachments';

export const MessageAttachmentsContainer = styled(View)`
  ${({ theme }) => css`
    background-color: rgba(255, 255, 255, 0.8);
    border-width: 1px;
    border-color: ${theme.colors.placeholderText};
    bottom: ${getFooterHeight(theme)}px;
    justify-content: center;
    left: 0;
    position: absolute;
    right: 0;
  `}
`;

export const MessageAttachmentsFilePreview = styled(FilePreview).attrs(
  ({ theme }) => ({
    containerStyle: {
      margin: theme.spacing * 0.75,
    },
  })
)``;

export const MessageAttachmentsIcon = styled(Icon).attrs(({ theme }) => ({
  containerStyle: {
    position: 'absolute',
    right: theme.spacing * -1,
    top: theme.spacing * -1,
  },
  color: 'red',
  raised: true,
  size: theme.spacing * 1.125,
}))``;

export const MessageAttachmentsScrollView = styled(ScrollView).attrs({
  contentContainerStyle: {
    justifyContent: 'center',
    minWidth: '100%',
  },
  horizontal: true,
})``;

export { default as MessageGroup } from './MessageGroup';

export const MessageGroupAuthorWrapper = styled(View)`
  flex-direction: row;
`;

export const MessageGroupContainer = styled(View)<{
  patientIsAuthor?: boolean;
}>`
  ${({ theme, patientIsAuthor }) => css`
    flex: 1 0 auto;
    flex-direction: row;
    margin-bottom: ${theme.spacing * 0.75}px;
    margin-left: ${theme.spacing / 2}px;
    margin-right: ${theme.spacing * 5}px;

    ${patientIsAuthor &&
    css`
      align-items: flex-end;
      justify-content: flex-end;
      margin-left: ${theme.spacing * 5}px;
      margin-right: ${theme.spacing / 2}px;
    `}
  `}
`;

export const MessageGroupContent = styled(View)<AuthorshipDependentView>`
  ${({ patientIsAuthor }) => css`
    align-items: flex-start;
    flex: 1 0 auto;
    flex-direction: row;

    ${patientIsAuthor &&
    css`
      justify-content: flex-end;
    `}
  `}
`;

export const MessageGroupContentWrapper = styled(View)`
  flex: 1 0 auto;
`;

export const MessageGroupLabel = styled(View)<AuthorshipDependentView>`
  ${({ theme, patientIsAuthor }) => css`
    ${patientIsAuthor &&
    css`
      align-items: flex-end;
    `}
    flex: 1 1 auto;
    margin-bottom: ${theme.spacing / 3}px;
  `}
`;

export const MessageGroupMessageContainer = styled(ResponsivePressable)`
  flex: 1 0 auto;
`;

export { MessageListEmpty } from './MessageListEmpty';

export { default as MessageLoadMore } from './MessageLoadMore';

export const MessageLoadMoreButton = styled(Button).attrs(({ theme }) => ({
  buttonStyle: { paddingHorizontal: theme.spacing * 1.25 },
  type: 'outline',
}))``;

export const MessageLoadMoreContainer = styled(View)`
  ${({ theme }) => css`
    align-items: center;
    margin-bottom: ${theme.spacing * 1.25}px;
  `}
`;

export { MessagesHeader } from './MessagesHeader';

export const MessagesHeaderContainer = styled(View).attrs(({ theme }) => ({
  ...Platform.select({
    // Render using fixed position on web
    web: {
      left: 0,
      position: 'fixed',
      right: 0,
      top: theme.navigationHeight - 2,
    },
  }),
}))`
  ${({ theme }) => css`
    background-color: ${theme.colors.white};
    border-bottom-color: ${theme.colors.mediumGrey};
    border-bottom-width: 1px;
    height: ${theme.spacing * 5.75}px;
    padding-bottom: ${theme.spacing / 4}px;
    padding-left: ${theme.spacing * 1.25}px;
    padding-right: ${theme.spacing * 1.25}px;
    padding-top: ${theme.spacing / 2}px;
    width: 100%;
    z-index: ${theme.zIndexes.base};
  `}
`;

export const MessagesHeaderContent = styled(View)`
  flex: 1;
  flex-direction: column;
  justify-content: space-evenly;
`;

export const MessagesHeaderRow = styled(View)`
  align-self: flex-start;
  align-items: center;
  flex-direction: row;
`;

export const MessagesHeaderTitle = styled(Text)`
  ${({ theme }) => css`
    font-size: ${theme.fontSizes.body2}px;
    margin-bottom: ${theme.spacing / 2}px;
  `}
`;

export const MessagesHeaderUserStatus = styled(
  View
)<MessagesHeaderUserStatusProps>`
  ${({ status, theme }) => css`
    align-items: flex-start;
    background-color: ${theme.colors.mediumGrey};
    background-color: ${getBackgroundColorForMessagesHeaderUserStatus(
      status,
      theme
    )};
    border-radius: ${theme.spacing / 4}px;
    height: ${theme.spacing / 2}px;
    margin-right: ${theme.spacing / 4}px;
    width: ${theme.spacing / 2}px;
  `}
`;

export const MessagesHeaderUserText = styled(Text)`
  ${({ theme }) => css`
    color: ${theme.colors.mediumGrey};
    font-size: ${theme.fontSizes.body2}px;
    margin-right: ${theme.spacing / 2}px;
  `}
`;

export const MessagesList = styled(FlatList).attrs((props) => {
  return {
    contentContainerStyle: { paddingVertical: props.theme.spacing / 2 },
  };
})`
  ${({ theme }) => css`
    flex: 1;
    flex-direction: column;
  `}
`;

export const MessageText = styled(Text)<MessageTextProps>`
  ${({ patientIsAuthor, isRead, theme }) => css`
    flex-wrap: wrap;
    font-family: ${theme.fontFamilyBold};
    font-size: ${theme.fontSizes.body2}px;

    ${patientIsAuthor &&
    css`
      color: ${theme.colors.white};
    `}
    ${isRead &&
    css`
      font-family: ${theme.fontFamily};
    `}
  `}
`;

export const MessageTextWrapper = styled(
  MessageContainer
)<MessageTextWrapperProps>`
  ${({ isFirst, isLast, isMiddle, patientIsAuthor, theme }) => css`
    align-items: flex-end;
    align-self: flex-start;
    background-color: ${theme.colors.lightGrey};

    ${isFirst &&
    patientIsAuthor &&
    css`
      border-bottom-right-radius: 0px;
    `}

    ${isLast &&
    patientIsAuthor &&
    css`
      border-top-right-radius: 0px;
    `}

    ${isMiddle &&
    patientIsAuthor &&
    css`
      border-bottom-right-radius: 0px;
      border-top-right-radius: 0px;
    `}


    ${isFirst &&
    !patientIsAuthor &&
    css`
      border-bottom-left-radius: 0px;
    `}

    ${isLast &&
    !patientIsAuthor &&
    css`
      border-top-left-radius: 0px;
    `}

    ${isMiddle &&
    !patientIsAuthor &&
    css`
      border-bottom-left-radius: 0px;
      border-top-left-radius: 0px;
    `}


    ${patientIsAuthor &&
    css`
      align-self: flex-end;
      background-color: ${theme.colors.mediumBlue};
    `}
  `}
`;

export const Notification = styled(Text)`
  ${({ theme }) => css`
    color: ${theme.colors.mediumGrey};
    margin-top: ${theme.spacing * 1.25}px;
    text-align: center;
  `}
`;

export const NotificationContainer = styled(View)`
  align-items: center;
`;

export { default as NotificationGroup } from './NotificationGroup';

export { NotificationGroupAvatar } from './styles-for-avatars';

export const NotificationGroupContainer = styled(View)`
  ${({ theme }) => css`
    flex: 1 0 auto;
    flex-direction: row;
    margin-bottom: ${theme.spacing * 0.75}px;
    margin-left: ${theme.spacing / 2}px;
    margin-right: ${theme.spacing * 5}px;
  `}
`;

export const NotificationGroupContent = styled(View)`
  ${({ theme }) => css`
    flex: 1;
    margin-left: ${theme.spacing / 2}px;
  `}
`;

export const NotificationGroupTimestamp = styled(Text)`
  ${({ theme }) => css`
    color: ${theme.colors.mediumGrey};
    font-size: ${theme.fontSizes.small}px;
    margin-bottom: ${theme.spacing / 4}px;
    margin-left: ${theme.spacing / 3}px;
    padding-top: ${theme.spacing / theme.spacing}px;
  `}
`;

export { default as NotificationMessage } from 'app/components/Messages/NotificationGroup/NotificationMessage';

export const NotificationMessageContainer = styled(MessageContainer)`
  ${({ theme }) => css`
    color: ${theme.colors.white};
    background-color: ${theme.colors.badge};
  `}
`;

export const NotificationMessageText = styled(Text)`
  ${({ theme }) => css`
    color: ${theme.colors.white};
    max-width: 100%;
  `}
`;

export const PhoneNumber = styled(View)`
  flex-direction: row;
`;

export { Platform };

export { RoundedAvatar } from './styles-for-avatars.js';

export { ScrollView };

export const TelephoneIcon = styled(Icon).attrs(({ theme }) => ({
  color: 'white',
  containerStyle: {
    marginRight: theme.spacing / 4,
  },
  name: 'call',
  size: theme.spacing * 1.125,
}))`
  ${({ theme }) => css`
    background-color: ${theme.colors.primary};
    border-radius: ${theme.spacing / 4}px;
  `}
`;

export const Timestamp = styled(Text)`
  ${({ theme }) => css`
    color: ${theme.colors.mediumGrey};
    font-size: ${theme.fontSizes.small}px;
    padding-top: 1px;
  `}
`;

export { View };

// aggregated style objects

export const ActionButtonsStyles = {
  Button: ResponsivePressable,

  CalendarIcon: styled(Icon).attrs(({ theme }) => ({
    name: 'calendar-today',
    color: theme.colors.primary,
    size: theme.spacing,
  }))`
    ${({ theme }) => css`
      margin-right: ${theme.spacing * 0.75}px;
    `}
  `,

  Icon: styled(Icon).attrs(({ theme }) => ({
    color: theme.colors.primary,
    containerStyle: {
      marginLeft: theme.spacing / 4,
    },
    size: theme.spacing * 2,
  }))``,

  LeftSide: styled(View)`
    align-items: center;
    flex-direction: row;
    flex-grow: 1;
    justify-content: flex-start;
  `,

  RightSide: styled(View)`
    align-items: center;
    flex-direction: row;
  `,

  SendButton,

  ScheduleCallButton: styled(TrackableButton).attrs({
    type: 'outline',
    containerStyle: {
      alignItems: 'baseline',
    },
    buttonStyle: {
      paddingVertical: 7, // NOTE: offset 1px width of outline
      paddingLeft: 20,
      paddingRight: 32,
    },
  })``,

  Wrapper: styled(View)`
    ${({ theme }) => css`
      align-items: center;
      background-color: ${theme.colors.sand};
      border-top-color: ${theme.colors.lightGrey};
      border-top-width: 1px;
      flex-direction: row;
      flex-shrink: 0;
      justify-content: space-between;
      padding: ${({ theme }) => theme.spacing}px;
    `}
  `,
};

export const FooterStyles = {
  Wrapper: styled(View)`
    ${({ theme }) => css`
      background-color: ${theme.colors.white};
      border-top-color: ${theme.colors.placeholderText};
      border-top-width: 1px;
      flex-direction: column;
    `}
  `,
};

export const InputFieldStyles = {
  Field: styled(TextArea).attrs({
    containerStyle: {
      borderRadius: 0,
      borderWidth: 0,
      flex: 1,
      marginBottom: 0,
      marginLeft: 0,
      marginTop: 0,
      paddingLeft: 0,
      paddingVertical: 0,
    },
  })`
    ${({ theme }) => css`
      font-size: ${theme.fontSizes.body2}px;
      margin-left: 0px;
      margin-top: 0px;
      padding: ${theme.spacing}px;
    `}
  `,

  SendButton,

  Wrapper: styled(View)`
    ${({ theme }) => css`
      align-items: center;
      flex-direction: row;
      justify-content: center;
      padding: ${theme.spacing}px 0;
    `}
  `,
};
