import { useEpisode } from 'app/hooks';

import {
  EVENTS,
  Routes,
  TestID,
} from 'app/components/ProcedureSearch/constants';

import {
  debounce,
  fetchPossibleProcedures,
  getProcedureSearchSuggestionMapping,
  isDownKey,
  isEnterKey,
  isUpKey,
  logEvent,
} from 'app/components/ProcedureSearch/helpers';

import {
  useCallback,
  useDispatch,
  useEffect,
  useHistory,
  useState,
} from 'app/components/ProcedureSearch/hooks';

import { ProcedureSearchPageSearchInterfaceStyles as Styled } from 'app/components/ProcedureSearch/styles';

import { KeyboardEvent } from 'app/components/ProcedureSearch/types';

const ProcedureSearchPageSearchInterface = ({
  isMediumOrLargerDevice,
  isSearching,
  queryHasNoResults,
  search,
  searchSuggestions,
  setIsSearching,
  setMapping,
  setSearch,
  query,
}: {
  isMediumOrLargerDevice: boolean;
  isSearching: any;
  queryHasNoResults: any;
  search: any;
  searchSuggestions: any;
  setIsSearching: any;
  setMapping: any;
  setSearch: any;
  query: any;
}) => {
  const [suggestionCursor, setSuggestionCursor] = useState<number | null>(null);

  const dispatch = useDispatch();
  const { possibleProcedures, possibleProcedureCategories } = useEpisode();
  const history = useHistory();

  /** When the search query changes, fetch procedures. */
  useEffect(() => {
    if (query) dispatch(fetchPossibleProcedures(query));
  }, [dispatch, query]);

  /** Set the searching state to false when a new procedures load. */
  useEffect(() => {
    setIsSearching(false);
  }, [possibleProcedures]);

  /** Log search queries in Google Analytics when new procedures load. */
  useEffect(() => {
    logSearch();
  }, [possibleProcedures]);

  /** Map procedure search suggestions to procedures when results are returned. */
  useEffect(() => {
    if (!query) return;
    if (!possibleProcedures?.length) return;

    const mapping = getProcedureSearchSuggestionMapping(
      possibleProcedures,
      query
    );

    setMapping(mapping);
  }, [possibleProcedures, query]);

  /** Log the current search query and results in Google Analytics. */
  const logSearch = useCallback(() => {
    if (!query) return;

    logEvent(EVENTS.procedures.search, {
      search: query,
      results: possibleProcedures.length,
    });
  }, [possibleProcedures, query]);

  const goToProcedureResults = (index: number) => {
    history.push(`/${Routes.Search}/${searchSuggestions[index][0]}`);
  };

  const handleEnterKeyPress = () => {
    if (!suggestionCursor) return;
    goToProcedureResults(suggestionCursor);
  };

  /** Handle key events for up, down, and enter. */
  const handleSearchKeyEvent = (event: KeyboardEvent) => {
    if (isUpKey(event) || isDownKey(event)) {
      updateSuggestionCursor(event);
    } else if (isEnterKey(event)) {
      handleEnterKeyPress();
    }
  };

  /**
   * Set the `isSearching` boolean when a user beings typing and
   * store the search query in the `search` state variable.
   */
  const onSearch = useCallback(async (searchText: string) => {
    setSearch(searchText);
    debounce(() => setIsSearching(searchText.trim().length !== 0), 500);
  }, []);

  const queryHasResults =
    Boolean(query) && !isSearching && searchSuggestions.length !== 0;

  const updateSuggestionCursor = (event: KeyboardEvent) => {
    let newCursor = suggestionCursor;
    const maxCursor = searchSuggestions.length - 1;

    const handleUpKey = () => {
      if (newCursor === null) newCursor = 0; // reset to zero if not yet defined
      newCursor -= 1; // go to next item in the list
      if (newCursor < 0) newCursor = maxCursor; // go to end of list if cursor goes above list
    };

    const handleDownKey = () => {
      if (newCursor === null) {
        newCursor = 0; // reset to zero if not yet defined
      } else {
        newCursor += 1; // to go next item in the list
      }
      if (newCursor > maxCursor) newCursor = 0; // reset to zero if cursor goes beyond list
    };

    if (isUpKey(event)) {
      handleUpKey();
    } else if (isDownKey(event)) {
      handleDownKey();
    }

    setSuggestionCursor(newCursor);
  };

  return (
    <Styled.SearchInterface>
      {isMediumOrLargerDevice && (
        <Styled.LogoWrapper>
          <Styled.RedVerticalLogo />
        </Styled.LogoWrapper>
      )}

      {(query.length === 0 || isMediumOrLargerDevice) && (
        <Styled.Title>
          Search for your health condition or procedure
        </Styled.Title>
      )}

      <Styled.SearchBar
        onBlur={() => setSuggestionCursor(null)}
        onChangeText={onSearch as any} // the typings are incorrect from the lib
        onKeyPress={handleSearchKeyEvent as any} // the typings are incorrect from the lib
        testID={TestID.ProcedureSearch.SearchInput}
        value={search}
      />

      {isSearching && (
        <Styled.DotIndicator testID={TestID.ProcedureSearch.LoadingIndicator} />
      )}

      {queryHasResults && (
        <Styled.SearchSuggestions
          onSelectItem={goToProcedureResults}
          searchSuggestions={searchSuggestions}
          suggestionCursor={suggestionCursor}
        />
      )}

      {queryHasNoResults && (
        <>
          <Styled.NoResultsText testID={TestID.ProcedureSearch.SearchResult}>
            No results found for &apos;{search.trim()}&apos;
          </Styled.NoResultsText>
        </>
      )}

      <Styled.SubCategoryButtons
        possibleProcedureCategories={possibleProcedureCategories}
      />
    </Styled.SearchInterface>
  );
};

export default ProcedureSearchPageSearchInterface;
