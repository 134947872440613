export { callCarrum } from 'app/util/call';

export { createEscalation } from 'app/actions/escalationActions';

export { disconnectFromWebSocket } from 'app/actions/messageActions';

export const extractBranding = (session) =>
  session?.user?.employer?.images?.logoMobile;

export const extractIncompleteFormCount = (submissions) => {
  return submissions.filter(({ completedOn }) => !completedOn).length;
};

export const extractUnreadMessageCount = (currentUserId, messages) => {
  const lastReadMessage = Array(...messages).filter(
    ({ readBy }) =>
      readBy.map((id) => parseInt(id)).indexOf(parseInt(currentUserId)) !== -1
  )[0];

  return Array(...messages).filter(
    ({ authorId, id, readBy }) =>
      parseInt(authorId) !== parseInt(currentUserId) &&
      parseInt(id) >= (lastReadMessage ? parseInt(lastReadMessage.id) : 0) &&
      readBy.map((id) => parseInt(id)).indexOf(parseInt(currentUserId)) === -1
  ).length;
};

export { getFaqUrl } from 'app/util/methods';

export { getHasConversation } from 'app/selectors/message';

export {
  getIsEpisodeCreated,
  getIsEpisodeTraveling,
} from 'app/selectors/episode';

export { getIsLoggedIn } from 'app/selectors/session';

export { getUserPhoneNumber } from 'app/selectors/session';

export { isEmpty } from 'app/util/methods';

export { logout } from 'app/actions/sessionActions';

export {
  receiveSetCallUsModalVisibility,
  receiveSetLiveChatModalVisibility,
} from 'app/actions/uiActions';

export { routeTo } from 'app/util/routeUtils';

export { setPanelOpen } from 'app/actions/uiActions';

export { setProfileMenuOpen } from 'app/actions/uiActions';

export { showDevTools } from 'app/util/devUtils';
