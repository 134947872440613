import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Platform, useWindowDimensions } from 'react-native';
//@ts-ignore
import { Redirect, useHistory } from '@cross-platform/react-router-native';

import {
  getEpisodeDistance,
  updateCurrentEpisode,
} from 'app/actions/episodeActions';
import {
  useEpisode,
  useNextPhaseRoute,
  usePossiblePhysicians,
  useSession,
} from 'app/hooks';
import { Routes } from 'app/util/routes';
import { TestID } from 'app/util/test-id';
import theme from 'app/util/theme';

import PricingFooter from './PricingFooter';
import { isSilverDoctor } from '../helpers';
import { SelectYourDoctorRoutes } from '../routes';

import {
  Anchor,
  Avatar,
  Button,
  Container,
  DisclaimerText,
  DoctorCard,
  FacilityCard,
  FullBenefitsText,
  Header,
  HospitalImage,
  InnerColumn,
  LearnMore,
  NavigationMode,
  OptionsWrapper,
  ScrollView,
  Selection,
  SelectionName,
  Selections,
  SelectionSubheader,
  StickyNavFooter,
  TopBackLink,
  YouSelected,
} from './styles';
import { getSilverFaqUrl } from 'app/util/methods';

const Confirmation = () => {
  const [isSaving, setIsSaving] = useState(false);
  const { refresh: refreshEpisode, tempCoeSelection } = useEpisode();
  const {
    data: { records: physicians },
  } = usePossiblePhysicians();
  const {
    user: { employer },
  } = useSession();
  const dispatch = useDispatch();
  const history = useHistory();
  const { nextRoute } = useNextPhaseRoute();
  const pollingInterval = useRef();
  const { width } = useWindowDimensions();

  const selectedPhysician = tempCoeSelection?.doctor;
  const selectedFacility = tempCoeSelection?.facility;
  const selectedProductOffering = selectedFacility?.productOffering;
  const isSilverSelected = selectedProductOffering?.code === 'silver';
  const isNarrow = width < theme.breakpoints.xsmall;

  const goldDoctors = physicians
    // only show gold doctors
    .filter((doctor) => !isSilverDoctor(doctor))
    // limit list to top 2
    .filter((__, index) => index < 2);

  const getPhysicianName = (physician) => {
    if (typeof physician?.name === 'string') {
      return physician.name;
    }

    if (typeof physician?.name === 'object') {
      return `${physician.name?.prefix} ${physician.name?.first} ${physician.name?.last}`;
    }

    return '';
  };

  /**
   * Save the selections on the current episode and begin polling for updates.
   *
   * NOTE: Because the next path is dynamic based on the completed steps and
   *       tasks for an episode, which depends on asynchronous background jobs,
   *       we need to poll for updates to indicate when we're ready to navigate
   *       to the next page.
   */
  const saveSelectedPhysician = async () => {
    setIsSaving(true);

    await dispatch(
      updateCurrentEpisode({
        physician: selectedPhysician,
        productOffering: selectedFacility.productOffering,
        provider: selectedFacility,
      })
    );

    // Update the stored distance on the episode after setting the hospital.
    dispatch(getEpisodeDistance());

    //@ts-ignore
    pollingInterval.current = setInterval(refreshEpisode, 1000);
  };

  useEffect(() => {
    return () => {
      setIsSaving(false);
    };
  }, []);

  /**
   * Navigate to the next journey phase intro page when the next route loads.
   */
  useEffect(() => {
    if (nextRoute.includes(Routes.SelectYourDoctor)) return;

    history.push(nextRoute);
  }, [history, nextRoute]);

  /**
   * Clear `pollingInterval` when this component unmounts.
   */
  useEffect(() => {
    return () => clearInterval(pollingInterval.current);
  }, []);

  const isPartnerClient = employer.type === 'partner';

  /* only show the NSA language for Gold selection AND non-partner client*/
  const showPricingFooter = !isSilverSelected && !isPartnerClient;

  if (!selectedPhysician)
    return <Redirect to={`${SelectYourDoctorRoutes.Doctors}`} />;

  return (
    <>
      <ScrollView>
        <Container testID={TestID.SelectYourDoctor.ConfirmationPage}>
          <InnerColumn>
            <TopBackLink />
            <Header>Facility &amp; Doctor</Header>
            <YouSelected>
              {isSilverSelected
                ? 'You selected Carrum Silver Experience with'
                : 'You selected'}
              :
            </YouSelected>
            <Selections>
              <Selection>
                <FacilityCard>
                  <HospitalImage
                    source={
                      Platform.OS === 'web'
                        ? selectedFacility.images.logo
                        : { uri: selectedFacility.images.logo }
                    }
                  />
                </FacilityCard>
                <SelectionName>{selectedFacility.name}</SelectionName>
                <SelectionSubheader>
                  {selectedFacility.address.city},{' '}
                  {selectedFacility.address.state}
                </SelectionSubheader>
              </Selection>
              <Selection>
                <Avatar
                  source={
                    Platform.OS === 'web'
                      ? selectedPhysician.profileImage
                      : { uri: selectedPhysician.profileImage }
                  }
                />
                <SelectionName>
                  {getPhysicianName(selectedPhysician)}
                </SelectionName>
                <SelectionSubheader>
                  {selectedPhysician.title}
                </SelectionSubheader>
              </Selection>
            </Selections>

            {isSilverSelected && (
              <LearnMore>
                Further steps are subject to the provider&apos;s availability.
                Some benefits may not be available, such as premium care
                specialist support or lower out-of-pocket cost. Learn more in
                our <Anchor title="FAQs." to={getSilverFaqUrl(employer)} />
              </LearnMore>
            )}

            {!isNarrow && (
              <Button
                loading={isSaving}
                title="I understand, continue"
                onPress={saveSelectedPhysician}
                testID={TestID.SelectYourDoctor.ConfirmationButton}
              />
            )}

            {isSilverSelected && goldDoctors.length > 0 && (
              <>
                <FullBenefitsText>
                  If you want full benefits, the following Gold Experience
                  providers are available:
                </FullBenefitsText>

                <OptionsWrapper screenWidth={width}>
                  {goldDoctors.map((card) => (
                    <DoctorCard id={card.id} key={card.id} {...card} />
                  ))}
                </OptionsWrapper>
              </>
            )}
            {showPricingFooter && <PricingFooter />}
            <DisclaimerText>
              Our doctors and facilities are always in high demand. Please note
              that under some circumstances, you may not be able to proceed with
              your selections. In case that happens, our Care Specialist Team
              will support you in selecting alternatives and continuing your
              care journey.
            </DisclaimerText>
          </InnerColumn>
        </Container>
      </ScrollView>

      {isNarrow && (
        <StickyNavFooter
          navigationConfig={[
            {
              disabled: isSaving,
              onPress: saveSelectedPhysician,
              testID: TestID.SelectYourDoctor.ConfirmationButtonNav,
              text: isSaving ? 'Saving' : 'I understand, continue',
            },
          ]}
          navigationMode={NavigationMode.Continue}
          testID={TestID.StickyNavFooter.Component}
        />
      )}
    </>
  );
};

export default Confirmation;
