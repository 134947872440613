import React from 'react';
import Svg, { Path } from 'react-native-svg';

/**
 * Renders Home Left image as SVG.
 */
const HomeLeft = () => (
  <Svg viewBox="0 0 420 500" fill="none">
    <Path
      d="M142.128 494.347V45.4316H136.594V494.442L90.762 497.895H136.594H142.128H187.96L142.128 494.347Z"
      fill="#F6B99A"
    />
    <Path
      d="M100.6 6.3158L75.3899 119.274H203.332L174.562 6.66316L100.6 6.3158Z"
      fill="#F6B99A"
    />
    <Path
      d="M124.047 165.042C123.753 165.042 123.554 151.179 123.585 134.084C123.617 116.989 123.9 103.158 124.184 103.158C124.467 103.158 124.688 117.021 124.646 134.126C124.604 151.232 124.341 165.042 124.047 165.042Z"
      fill="#F6B99A"
    />
    <Path
      d="M123.994 164.832C123.994 164.695 124.908 164.832 125.307 165.979C125.431 166.329 125.466 166.705 125.41 167.073C125.353 167.44 125.206 167.788 124.981 168.084C124.671 168.429 124.264 168.673 123.814 168.785C123.364 168.896 122.891 168.869 122.456 168.708C122.021 168.548 121.644 168.26 121.373 167.882C121.103 167.504 120.952 167.054 120.939 166.589C120.96 166.23 121.066 165.882 121.248 165.572C121.431 165.263 121.685 165.002 121.989 164.811C123.039 164.168 123.847 164.695 123.742 164.811C123.637 164.926 123.07 164.811 122.43 165.421C122.263 165.572 122.129 165.755 122.035 165.96C121.941 166.164 121.89 166.386 121.884 166.611C121.908 166.865 122.008 167.107 122.171 167.304C122.334 167.502 122.552 167.645 122.797 167.716C123.03 167.819 123.287 167.85 123.537 167.807C123.788 167.764 124.02 167.648 124.204 167.474C124.347 167.299 124.452 167.097 124.512 166.879C124.571 166.661 124.585 166.434 124.551 166.211C124.488 165.263 123.9 164.968 123.994 164.832Z"
      fill="#F6B99A"
    />
    <Path
      d="M53.2036 367.126C53.7286 364.337 53.3716 361.442 53.9386 356.179C54.5056 350.916 56.7841 348.811 58.0441 345.095C59.3041 341.379 61.0051 332.632 61.0051 332.632H59.6296C59.6296 332.632 55.1356 348.832 53.8441 352.495C52.5526 356.158 52.5841 359.484 52.3636 362.821C52.0498 365.81 51.5731 368.779 50.9356 371.716C50.9356 371.716 49.2661 352.947 48.5836 346.347C47.9011 339.747 48.2161 326.937 48.2161 326.937L46.6306 326.2V335.916C46.6306 335.916 47.2501 345.263 47.8066 350.526C48.3631 355.789 49.5706 373.105 49.2556 385.779C49.1357 393.725 50.0751 401.651 52.0486 409.347C52.0486 409.758 52.5211 422.274 52.2901 428.484C52.0591 434.695 51.9331 459.232 52.1746 460.326L57.1831 461.642C57.1831 461.642 54.2746 440 54.8101 424.358C55.3456 408.716 54.1591 409.568 52.3006 402.084C50.4421 394.6 51.0196 374.305 51.0196 374.305C51.9127 371.966 52.6425 369.567 53.2036 367.126Z"
      fill="#3A4750"
    />
    <Path
      d="M58.9784 334.737C63.6377 333.035 67.707 330.019 70.6964 326.053C75.2534 319.674 78.2249 314.474 82.9499 311.958C73.8464 307.516 68.2499 316.4 63.7034 319.095C59.1569 321.789 54.5999 323.737 55.3034 328.947C56.0069 334.158 58.9784 334.737 58.9784 334.737Z"
      fill="#EF7136"
    />
    <Path
      d="M88.2524 255.589C87.8134 257.502 87.27 259.389 86.6249 261.242C85.8126 263.597 84.8732 265.905 83.8109 268.158C82.7586 270.422 81.5151 272.591 80.0939 274.642C78.9157 276.436 77.3869 277.973 75.5999 279.158C75.267 279.363 74.9154 279.536 74.5499 279.674C70.5339 281.093 66.6656 282.902 62.9999 285.074C60.8391 280.746 59.9782 275.882 60.5219 271.074C60.7475 269.418 61.1597 267.794 61.7504 266.232C62.2981 264.779 62.9581 263.371 63.7244 262.021C64.1024 261.326 64.4594 260.705 64.7744 260.168C65.0894 259.632 65.3939 259.032 65.7299 258.453C67.4856 255.299 69.6734 252.408 72.2294 249.863C72.7964 249.305 73.3949 248.747 74.0459 248.2C74.806 247.571 75.6421 247.041 76.5344 246.621C79.7931 245.271 83.2996 244.625 86.8244 244.726C86.8244 244.726 87.8744 246.084 88.7459 250.716C88.7459 250.811 88.7459 250.916 88.7459 251.021C88.8015 252.56 88.6353 254.098 88.2524 255.589Z"
      fill="#EF7136"
    />
    <Path
      d="M68.9535 301.053C68.9535 301.053 81.5535 291.579 97.4715 293.337C88.9035 284.074 81.9 284.453 75.9465 287.737C69.993 291.021 66.3285 293.568 68.9535 301.053Z"
      fill="#EF7136"
    />
    <Path
      d="M54.4216 286.042C54.4216 286.042 48.3001 277.358 39.9001 275.042C34.0025 273.601 27.9397 272.953 21.8716 273.116C23.5753 270.759 25.8552 268.881 28.4921 267.662C31.129 266.444 34.0343 265.926 36.9286 266.158C47.2501 266.737 50.4001 269.642 52.1536 273.526C53.9071 277.411 55.8286 281.6 54.4216 286.042Z"
      fill="#EF7136"
    />
    <Path
      d="M53.2034 314.221C53.2034 315.411 53.1299 316.537 53.0669 317.611C52.6889 323.337 51.5024 327.274 47.0714 327.926C45.0716 327.258 43.2471 326.148 41.7331 324.678C40.2191 323.207 39.0543 321.414 38.3249 319.432C35.8784 312.684 37.2749 311.526 33.5999 307.084C29.9249 302.642 28.0034 291.642 28.0034 291.642C32.3437 290.566 36.9227 291.042 40.9499 292.989L41.2334 293.147C47.5964 296.579 53.3714 306.8 53.2034 314.221Z"
      fill="#EF7136"
    />
    <Path
      d="M36.5716 334.484C36.5716 334.484 35.5216 336.032 29.0536 336.589C22.5856 337.147 21.0001 342.979 13.6501 344.326C6.3001 345.674 5.2501 345.484 2.1001 343.368C3.5281 338.495 7.0771 337.053 10.3216 334.221C11.4042 333.301 12.3578 332.239 13.1566 331.063C13.2711 330.915 13.3763 330.761 13.4716 330.6C14.9366 328.527 17.0623 327.016 19.4986 326.316C21.3614 325.695 23.3107 325.376 25.2736 325.368C27.5323 325.277 29.7816 325.709 31.8466 326.632C36.4036 328.895 36.4036 331.789 36.5716 334.484Z"
      fill="#EF7136"
    />
    <Path
      d="M84.4411 454.653C83.2441 467.642 79.4632 480.258 73.3216 491.758C71.6731 494.811 67.9771 496.789 63.8716 496.789H45.2236C41.1286 496.789 37.4221 494.811 35.7736 491.758C29.632 480.258 25.8511 467.642 24.6541 454.653C24.1441 449.522 23.9478 444.365 24.0661 439.211H85.0501C85.1614 444.366 84.958 449.523 84.4411 454.653Z"
      fill="#5F7881"
    />
    <Path
      d="M85.1654 454.653C83.9352 467.948 80.0615 480.861 73.7729 492.632C72.0824 495.789 68.3024 497.789 64.1024 497.789H44.9924C40.7924 497.789 37.0124 495.768 35.3219 492.632C29.0307 480.855 25.1569 467.934 23.9294 454.632L85.1654 454.653Z"
      fill="#3A4750"
    />
    <Path
      d="M73.9199 415.621C70.3289 411.495 65.1944 408.895 60.0179 409.147L59.8709 409.295C59.4292 412.2 59.694 415.169 60.6428 417.949C61.5916 420.729 63.1963 423.238 65.3204 425.263C69.4153 428.982 74.7471 431.035 80.2724 431.021C79.7374 425.367 77.5248 420.003 73.9199 415.621Z"
      fill="#EF7136"
    />
    <Path
      d="M42.693 421.663C40.5549 421.343 38.3701 421.639 36.393 422.516C31.4838 424.375 27.068 427.343 23.4885 431.189C27.825 432.842 32.361 434.347 36.918 433.811C41.475 433.274 46.074 430.084 47.208 425.189C46.788 422.863 44.793 422.105 42.693 421.663Z"
      fill="#EF7136"
    />
    <Path
      d="M12.1064 391.579C13.1469 393.327 14.718 394.696 16.5899 395.484C18.4073 396.228 20.1843 397.068 21.9134 398C24.9374 399.884 27.1634 403.053 29.9459 405.284C31.8969 406.838 34.1839 407.912 36.6237 408.421C39.0634 408.929 41.588 408.857 43.995 408.211C44.3659 405.558 44.1842 402.858 43.4613 400.28C42.7384 397.701 41.49 395.302 39.795 393.232C38.1781 391.305 36.1852 389.73 33.939 388.605C31.6927 387.479 29.2404 386.825 26.7329 386.684C21.7258 386.524 16.8212 388.13 12.8729 391.221"
      fill="#EF7136"
    />
    <Path
      d="M56.7944 397.684C55.0409 392.916 56.7944 386.547 60.1859 383.021C62.4119 380.716 65.3414 379.463 68.2079 378.305C70.6019 377.351 73.0064 376.418 75.4214 375.505C75.6885 375.362 75.9821 375.277 76.2839 375.253C76.5856 375.229 76.889 375.269 77.1749 375.368C77.9939 375.789 78.0989 377 78.0464 377.989C77.929 382.019 76.9251 385.973 75.1064 389.568C73.3823 393.022 70.4802 395.741 66.9269 397.232C63.4724 398.547 59.8079 400.011 56.7944 397.726"
      fill="#EF7136"
    />
    <Path
      d="M43.05 379.832C42.91 378.676 42.5175 377.565 41.9004 376.579C41.2834 375.593 40.4569 374.756 39.48 374.126C37.6011 372.972 35.4645 372.306 33.264 372.189C28.0823 371.741 22.8854 372.859 18.3435 375.4C22.421 378.482 26.9611 380.894 31.794 382.547C33.7353 383.33 35.8282 383.661 37.9155 383.516C38.9979 383.412 40.0365 383.035 40.9343 382.421C41.8322 381.806 42.5601 380.973 43.05 380"
      fill="#EF7136"
    />
    <Path
      d="M43.0501 354.589C39.3476 352.34 35.1891 350.952 30.8806 350.526C31.4476 355.463 32.4031 360.705 35.5741 364.211C37.4252 366.303 39.9998 367.612 42.7771 367.874C45.6737 368.119 48.5591 367.287 50.8831 365.537V365.295C49.738 360.851 46.9338 357.019 43.0501 354.589Z"
      fill="#EF7136"
    />
    <Path
      d="M65.94 344.484C63.63 346.168 63.4305 350.042 64.7745 352.716C66.1185 355.389 68.5545 357.126 70.8855 358.768C73.869 360.98 77.0298 362.942 80.3355 364.632C84.5355 366.663 89.271 367.79 93.051 370.737C93.5663 365.154 92.7753 359.528 90.741 354.305C88.641 349.221 84.8715 344.832 80.1465 342.811C77.7896 341.764 75.1907 341.388 72.6347 341.724C70.0787 342.06 67.6644 343.095 65.6565 344.716"
      fill="#EF7136"
    />
    <Path
      d="M66.15 345.579C66.15 345.663 65.016 346.211 63.3255 347.432C62.3312 348.159 61.3907 348.957 60.5115 349.821C59.4345 350.911 58.4477 352.087 57.561 353.337C56.6886 354.626 55.9163 355.981 55.251 357.389C54.7066 358.578 54.2436 359.801 53.865 361.053C53.235 363.158 53.067 364.558 52.983 364.537C52.9702 364.212 52.9949 363.887 53.0565 363.568C53.0929 363.185 53.1561 362.806 53.2455 362.432C53.3277 361.93 53.4399 361.435 53.5815 360.947C53.9161 359.681 54.3479 358.442 54.873 357.242C55.5276 355.794 56.3076 354.406 57.204 353.095C58.1009 351.799 59.113 350.587 60.228 349.474C61.1296 348.61 62.1026 347.825 63.1365 347.126C63.567 346.821 63.987 346.6 64.344 346.389C64.6471 346.203 64.9629 346.037 65.289 345.895C65.5613 345.753 65.8507 345.647 66.15 345.579Z"
      fill="#3A4750"
    />
    <Path
      d="M87.927 361.053C87.661 360.744 87.4221 360.413 87.213 360.063C86.772 359.421 86.163 358.484 85.2705 357.379C83.0828 354.526 80.5584 351.949 77.7525 349.705C76.263 348.478 74.6433 347.419 72.9225 346.547C71.5514 345.857 70.0461 345.476 68.5125 345.432C67.4766 345.416 66.4482 345.609 65.4885 346C64.806 346.284 64.4385 346.526 64.4385 346.495C64.4385 346.463 64.512 346.411 64.6695 346.284C64.9104 346.115 65.163 345.964 65.4255 345.832C66.3834 345.359 67.4345 345.107 68.502 345.095C70.0882 345.09 71.6543 345.45 73.08 346.147C74.8385 347.004 76.4912 348.064 78.0045 349.305C80.8436 351.57 83.3653 354.208 85.5015 357.147C86.331 358.284 86.9505 359.253 87.3495 359.937C87.581 360.287 87.7746 360.661 87.927 361.053Z"
      fill="#3A4750"
    />
    <Path
      d="M75.2221 314.337C75.2221 314.411 74.0356 315.221 72.2086 316.726C69.801 318.711 67.6053 320.94 65.6566 323.379C63.6878 325.878 61.968 328.565 60.5221 331.4C59.3986 333.6 58.8421 335.032 58.7686 335C58.8426 334.638 58.9553 334.285 59.1046 333.947C59.3356 333.284 59.7241 332.358 60.2491 331.232C61.6335 328.322 63.3419 325.578 65.3416 323.053C67.2962 320.586 69.5421 318.366 72.0301 316.442C72.7661 315.863 73.5338 315.325 74.3296 314.832C74.6087 314.635 74.9079 314.47 75.2221 314.337Z"
      fill="#3A4750"
    />
    <Path
      d="M36.3826 296.337C36.4868 296.386 36.5853 296.446 36.6766 296.516C36.8551 296.642 37.1386 296.821 37.4746 297.095C38.4323 297.853 39.3341 298.68 40.1731 299.568C43.0032 302.627 45.1509 306.255 46.4731 310.211C47.818 314.276 48.3218 318.574 47.9536 322.842C47.8465 324.128 47.6501 325.405 47.3656 326.663C47.2711 327.105 47.1661 327.442 47.1031 327.716C47.0839 327.834 47.0449 327.948 46.9876 328.053C47.0301 327.585 47.1037 327.121 47.2081 326.663C47.4237 325.402 47.5709 324.13 47.6491 322.853C47.9261 318.649 47.3913 314.432 46.0741 310.432C44.7536 306.547 42.6684 302.967 39.9421 299.905C39.1278 299 38.2618 298.142 37.3486 297.337C37.0013 297.029 36.6784 296.695 36.3826 296.337Z"
      fill="#3A4750"
    />
    <Path
      d="M16.6321 331.674C16.7925 331.395 16.9943 331.143 17.2306 330.926C17.8277 330.308 18.4935 329.761 19.2151 329.295C21.5501 327.766 24.3629 327.148 27.1216 327.558C28.9386 327.9 30.6545 328.652 32.1403 329.755C33.6261 330.858 34.8432 332.284 35.7001 333.926C35.8658 334.206 35.993 334.507 36.0781 334.821C35.447 333.818 34.7452 332.861 33.9781 331.958C32.1571 329.91 29.7077 328.529 27.0166 328.032C24.3485 327.639 21.6273 328.19 19.3201 329.589C18.3734 330.216 17.4749 330.913 16.6321 331.674Z"
      fill="#3A4750"
    />
    <Path
      d="M50.5469 364.937C48.2585 363.922 46.0352 362.765 43.8899 361.474C41.6536 360.318 39.4932 359.021 37.4219 357.589C39.7149 358.609 41.9449 359.765 44.0999 361.053C46.3255 362.215 48.4784 363.512 50.5469 364.937Z"
      fill="#3A4750"
    />
    <Path
      d="M25.2944 374.347C25.533 374.278 25.7815 374.25 26.0294 374.263C26.7045 374.228 27.3809 374.228 28.0559 374.263C30.2958 374.409 32.5099 374.826 34.6499 375.505C36.8126 376.182 38.8883 377.111 40.8344 378.274C41.4307 378.627 42.009 379.01 42.5669 379.421C42.7847 379.558 42.9826 379.725 43.1549 379.916C43.1549 379.979 42.2099 379.389 40.6874 378.568C38.7227 377.507 36.6626 376.633 34.5344 375.958C32.4183 375.308 30.2456 374.861 28.0454 374.621C26.3444 374.442 25.2944 374.421 25.2944 374.347Z"
      fill="#3A4750"
    />
    <Path
      d="M75.2535 377.421C74.9974 377.672 74.7118 377.892 74.403 378.074C73.8255 378.453 73.017 379.032 72.0405 379.779C69.4562 381.723 67.046 383.889 64.8375 386.253C62.6139 388.674 60.5945 391.276 58.8 394.032C58.107 395.084 57.561 395.979 57.204 396.6C57.0328 396.929 56.8288 397.239 56.595 397.526C56.7053 397.158 56.8604 396.804 57.057 396.474C57.3825 395.821 57.8865 394.905 58.5585 393.811C60.2878 390.974 62.2963 388.319 64.554 385.884C66.7755 383.494 69.2387 381.343 71.904 379.463C72.954 378.758 73.752 378.221 74.3505 377.884C74.6327 377.696 74.9357 377.54 75.2535 377.421Z"
      fill="#3A4750"
    />
    <Path
      d="M18.0916 391.232C18.5087 391.172 18.9309 391.154 19.3516 391.179C20.1601 391.179 21.3361 391.179 22.7851 391.274C24.4926 391.375 26.1889 391.618 27.8566 392C29.9376 392.469 31.916 393.314 33.6946 394.495C35.4974 395.744 37.0981 397.263 38.4406 399C39.5619 400.431 40.5862 401.936 41.5066 403.505C42.3046 404.842 42.9031 405.947 43.3126 406.726C43.5483 407.113 43.7452 407.522 43.9006 407.947C43.6251 407.595 43.3826 407.218 43.1761 406.821C42.7246 406.084 42.1261 405 41.2546 403.663C40.2914 402.133 39.2396 400.66 38.1046 399.253C36.7684 397.569 35.1943 396.091 33.4321 394.863C31.6841 393.715 29.7518 392.879 27.7201 392.389C26.077 391.992 24.4062 391.722 22.7221 391.579C21.2836 391.442 20.1181 391.379 19.3096 391.326C18.9016 391.331 18.494 391.299 18.0916 391.232Z"
      fill="#3A4750"
    />
    <Path
      d="M47.2501 424.884C46.4248 424.995 45.5941 425.062 44.7616 425.084C42.7592 425.249 40.7766 425.602 38.8396 426.137C36.8857 426.662 34.9841 427.367 33.1591 428.242C32.4152 428.633 31.6511 428.985 30.8701 429.295C30.8701 429.295 31.0486 429.116 31.4266 428.863C31.8046 428.611 32.3401 428.274 33.0331 427.895C34.8436 426.927 36.7559 426.165 38.7346 425.621C40.6949 425.089 42.7106 424.788 44.7406 424.726C45.3526 424.69 45.9662 424.69 46.5781 424.726C46.8098 424.738 47.0374 424.792 47.2501 424.884Z"
      fill="#3A4750"
    />
    <Path
      d="M76.6501 428.505C76.5661 428.6 72.6601 424.453 67.9246 419.253C63.1891 414.053 59.4196 409.779 59.5246 409.663C59.6296 409.547 63.5041 413.684 68.2501 418.947C72.9961 424.211 76.7446 428.421 76.6501 428.505Z"
      fill="#3A4750"
    />
    <Path
      d="M60.648 409.705C59.4814 409.774 58.3243 409.957 57.1935 410.253C56.0943 410.704 55.0396 411.257 54.0435 411.905C54.7919 410.883 55.8602 410.142 57.078 409.8C58.2179 409.339 59.4853 409.306 60.648 409.705Z"
      fill="#3A4750"
    />
    <Path
      d="M54.6 420.442C54.5265 420.442 54.1275 419.6 53.3085 418.337C52.2355 416.654 50.9821 415.094 49.5705 413.684C47.964 412.053 46.4205 410.716 45.2655 409.726C44.6181 409.235 44.017 408.686 43.47 408.084C44.175 408.476 44.8367 408.942 45.444 409.474C47.0011 410.666 48.4778 411.96 49.8645 413.347C51.2965 414.772 52.5356 416.378 53.55 418.126C53.8573 418.661 54.1274 419.217 54.3585 419.789C54.4702 419.994 54.5515 420.214 54.6 420.442Z"
      fill="#3A4750"
    />
    <Path
      d="M52.9096 424.642C51.9797 425.126 50.9265 425.321 49.8856 425.2C48.8354 425.231 47.8003 424.945 46.9141 424.379C46.9141 424.242 48.2581 424.663 49.9066 424.726C51.5551 424.789 52.8781 424.505 52.9096 424.642Z"
      fill="#3A4750"
    />
    <Path
      d="M59.0941 393.895C59.1571 393.895 58.6006 394.832 57.8341 396.358C56.8331 398.355 56.0109 400.436 55.3771 402.579C54.7511 404.748 54.312 406.966 54.0646 409.211C53.8756 410.937 53.8441 412.021 53.7811 412.021C53.7196 411.77 53.6949 411.511 53.7076 411.253C53.7076 410.768 53.7076 410.053 53.7601 409.147C53.9252 406.865 54.3299 404.607 54.9676 402.411C55.604 400.24 56.4772 398.147 57.5716 396.168C57.89 395.574 58.2406 394.998 58.6216 394.442C58.9156 394.074 59.0731 393.874 59.0941 393.895Z"
      fill="#3A4750"
    />
    <Path
      d="M50.3369 388.716C50.2739 388.716 50.0849 388.032 49.5809 387.021C48.9101 385.735 48.042 384.563 47.0084 383.547C45.9767 382.56 44.8748 381.649 43.7114 380.821C43.2023 380.504 42.7364 380.122 42.3254 379.684C42.8772 379.895 43.3968 380.182 43.8689 380.537C45.084 381.29 46.2171 382.168 47.2499 383.158C48.3294 384.208 49.199 385.455 49.8119 386.832C50.1231 387.413 50.3024 388.057 50.3369 388.716Z"
      fill="#3A4750"
    />
    <Path
      d="M49.0035 348.99C48.9405 348.99 48.4155 347.937 47.439 346.263C46.1769 344.107 44.7257 342.067 43.1025 340.168C41.5112 338.316 39.7538 336.615 37.8525 335.084C36.3825 333.926 35.427 333.295 35.4585 333.232C35.7183 333.309 35.9632 333.43 36.183 333.589C36.6345 333.853 37.233 334.263 38.0205 334.811C40.018 336.275 41.8428 337.964 43.4595 339.842C45.1029 341.766 46.5388 343.858 47.7435 346.084C48.195 346.947 48.5415 347.663 48.7935 348.189C48.904 348.444 48.9748 348.714 49.0035 348.99Z"
      fill="#3A4750"
    />
    <Path
      d="M79.9575 295.368C79.8003 295.469 79.6352 295.557 79.464 295.632L78.0255 296.316C76.776 296.905 74.9595 297.726 72.7755 298.926C70.1942 300.307 67.8161 302.039 65.709 304.074C63.1413 306.721 60.9601 309.719 59.2305 312.979C57.3195 316.463 55.797 319.989 54.4215 323.179C53.046 326.368 51.8175 329.253 50.7675 331.695L48.3 337.411L47.5965 338.947C47.5172 339.129 47.426 339.305 47.3235 339.474C47.3699 339.279 47.4332 339.089 47.5125 338.905C47.67 338.495 47.88 337.979 48.1425 337.326C48.72 335.905 49.5285 333.947 50.5155 331.537C51.5025 329.126 52.752 326.2 54.0855 322.989C55.5078 319.488 57.1094 316.062 58.884 312.726C60.6329 309.426 62.8505 306.398 65.4675 303.737C67.6065 301.693 70.0291 299.97 72.66 298.621C74.8545 297.453 76.692 296.674 77.91 296.126L79.38 295.537C79.5616 295.448 79.7569 295.391 79.9575 295.368Z"
      fill="#3A4750"
    />
    <Path
      d="M50.988 277.726C51.2314 278.11 51.4456 278.511 51.6285 278.926C52.017 279.705 52.542 280.874 53.151 282.326C54.7258 286.139 56.0207 290.062 57.0255 294.063C58.0245 298.097 58.7263 302.2 59.1255 306.337C59.283 307.937 59.3775 309.221 59.409 310.116C59.4457 310.578 59.4457 311.043 59.409 311.505C59.3095 311.055 59.2498 310.597 59.2305 310.137C59.1465 309.242 59.01 307.958 58.821 306.379C58.3105 302.274 57.5531 298.203 56.553 294.189C55.546 290.203 54.2941 286.284 52.8045 282.453C52.2375 280.989 51.7545 279.811 51.4185 279.011C51.2341 278.597 51.0899 278.167 50.988 277.726Z"
      fill="#3A4750"
    />
    <Path
      d="M78.5504 253.484C78.4855 253.656 78.4047 253.822 78.3089 253.979C78.1199 254.347 77.8679 254.8 77.5529 255.368C76.8914 256.579 75.9149 258.295 74.7599 260.463C72.4289 264.779 69.3104 270.811 66.3599 277.737C64.9004 281.211 63.6089 284.579 62.5589 287.684C61.5089 290.789 60.7424 293.653 60.1544 296.105C59.5664 298.558 59.2304 300.526 58.9994 301.905C58.8944 302.558 58.8104 303.084 58.7474 303.495C58.7306 303.685 58.6918 303.872 58.6319 304.053C58.6178 303.863 58.6178 303.673 58.6319 303.484C58.6319 303.116 58.7054 302.568 58.7999 301.874C58.9784 300.484 59.2829 298.463 59.8499 296.021C60.4169 293.579 61.1624 290.695 62.1809 287.6C63.1994 284.505 64.4909 281.084 65.9609 277.6C68.5121 271.681 71.3793 265.905 74.5499 260.295C75.7259 258.137 76.7549 256.442 77.4584 255.274L78.2774 253.937C78.355 253.778 78.4464 253.627 78.5504 253.484Z"
      fill="#3A4750"
    />
  </Svg>
);

export default HomeLeft;
