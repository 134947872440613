import {
  defaultAuthorProfileImage,
  TestID,
} from 'app/components/Messages/constants';

import { callCarrum, formatPhoneNumber } from 'app/components/Messages/helpers';

import {
  AuthorName,
  RoundedAvatar,
  CallCarrumLink,
  MessageGroupContentWrapper,
  DeliveredIcon,
  IconWrapper,
  MessageGroupLabel,
  Message,
  MessageGroupAuthorWrapper,
  MessageGroupContainer,
  MessageGroupContent,
  PhoneNumber,
  Platform,
  TelephoneIcon,
  Timestamp,
} from 'app/components/Messages/styles';

import { MessageGroupProps } from 'app/components/Messages/types';

/**
 * Render a group of messages based on the author and timestamp of the message.
 */
const MessageGroup = ({
  author,
  messageIsLastInConversation,
  messages,
  patientIsAuthor,
  timestamp,
}: MessageGroupProps) => {
  // Display the delivery icon when the message group belongs to the user, is
  // the last message in the conversation, and is pending.
  const deliveredIconIsDisplayed =
    patientIsAuthor &&
    messageIsLastInConversation &&
    !messages[messages.length - 1].pending;

  const authorProfileImage =
    Platform.OS === 'web' ? author.profileImage : { uri: author.profileImage };
  const authorProfileImageSource = author.profileImage
    ? authorProfileImage
    : defaultAuthorProfileImage;

  return (
    <MessageGroupContainer
      testID={TestID.Messages.MessageGroup}
      patientIsAuthor={patientIsAuthor}
    >
      <MessageGroupContent patientIsAuthor={patientIsAuthor}>
        {!patientIsAuthor && (
          <RoundedAvatar rounded source={authorProfileImageSource} />
        )}

        <MessageGroupContentWrapper>
          <MessageGroupLabel patientIsAuthor={patientIsAuthor}>
            {!patientIsAuthor && (
              <MessageGroupAuthorWrapper>
                <AuthorName>{author.name}</AuthorName>
                {author.phoneNumber && (
                  <PhoneNumber>
                    <TelephoneIcon />
                    <CallCarrumLink
                      onPress={(e) => callCarrum(e, author.phoneNumber)}
                      title={formatPhoneNumber(author.phoneNumber)}
                    />
                  </PhoneNumber>
                )}
              </MessageGroupAuthorWrapper>
            )}
            <Timestamp>{timestamp}</Timestamp>
          </MessageGroupLabel>

          {messages.map((message, index) => {
            return (
              <Message
                key={message.id}
                count={messages.length}
                index={index}
                patientIsAuthor={patientIsAuthor}
                {...message}
              />
            );
          })}
        </MessageGroupContentWrapper>
      </MessageGroupContent>

      {deliveredIconIsDisplayed && (
        <IconWrapper>
          <DeliveredIcon />
        </IconWrapper>
      )}
    </MessageGroupContainer>
  );
};

MessageGroup.displayName = 'MessageGroup';

export default MessageGroup;
