import axios from 'axios';
import { createUpload } from 'app/actions/uploadActions';
import { incrementBadgeNumber } from 'app/actions/notificationActions';
import { logError } from 'app/util/methods';
import { MESSAGE_API_URL } from 'app/util/constants';
import { showGlobalError, showLocalNotification } from 'app/actions/uiActions';
import { setBadgeNumber } from 'app/actions/notificationActions';
import { Subscription } from 'app/util/webSockets';
import { unformatObject } from 'app/util/reducerUtils';

export const RECEIVE_CONVERSATION = 'RECEIVE_CONVERSATION';
export const RECEIVE_CONVERSATIONS = 'RECEIVE_CONVERSATIONS';
export const RECEIVE_CURRENT_CONVERSATION = 'RECEIVE_CURRENT_CONVERSATION';
export const RECEIVE_MESSAGE = 'RECEIVE_MESSAGE';
export const RECEIVE_MESSAGES = 'RECEIVE_MESSAGES';
export const RECEIVE_MESSAGES_OPEN = 'RECEIVE_MESSAGES_OPEN';
export const RECEIVE_USER_STATUS = 'RECEIVE_USER_STATUS';
export const RECEIVE_USER_STATUSES = 'RECEIVE_USER_STATUSES';
export const RECEIVE_WEBSOCKET_CONNECTED = 'RECEIVE_WEBSOCKET_CONNECTED';
export const RECEIVE_IS_CREATING_CONVERSATION =
  'RECEIVE_IS_CREATING_CONVERSATION';
let retries = 0;
let retryTimeout = 0;

/**
 * Return a list of messages that have not been read by a given user id.
 *
 * @param   {Array}    messages       an array of message objects
 * @param   {integer}  currentUserId  the ID of the current user
 *
 * @return  {Array}                   an array of unread messages
 */
const getUnreadMessages = (messages, currentUserId) => {
  const lastReadMessage = messages.filter(
    ({ readBy }) =>
      readBy.map((id) => parseInt(id)).indexOf(parseInt(currentUserId)) !== -1
  )[0];

  return messages.filter(
    ({ authorId, id, readBy }) =>
      parseInt(authorId) !== parseInt(currentUserId) &&
      parseInt(id) >= (lastReadMessage ? parseInt(lastReadMessage.id) : 0) &&
      readBy.map((r) => parseInt(r)).indexOf(parseInt(currentUserId)) === -1
  );
};

/**
 * Connect to the ActionCable server for the Messaging Service. When connected,
 * automatically subscribes to the UserStatusChannel.
 *
 * @return  {promise}  a promise that resolves after connecting
 */
export const connectToWebSocket = () => {
  return async (dispatch, getState) => {
    if (Subscription.connected) return;

    try {
      retries = 0;

      Subscription.authorize(getState().session.coreToken);
      await dispatch(subscribeToChannels());
      await dispatch(fetchMessageData());
    } catch (error) {
      dispatch(receiveConnectionError(error));
    }
  };
};

/**
 * Emit a WebSocket event to the MessagesChannel to indicate a new message in a
 * conversation.
 *
 * @param  {integer}  conversationId  the ID of the conversation
 * @param  {object}   message         an object describing the message
 */
export const createMessage = (conversationId, message) => {
  return async (dispatch, getState) => {
    try {
      if (!conversationId) {
        await createConversationWithInitialMessage(dispatch, getState, message);
        return;
      }
      dispatch(createPendingMessage(conversationId, message));

      let messagesChannel = Subscription.find({
        channel: 'MessagesChannel',
        conversation_id: conversationId,
      });

      if (!messagesChannel) {
        messagesChannel = await dispatch(subscribeToMessages(conversationId));
      }

      if (message.body) {
        messagesChannel.perform(
          'create',
          unformatObject({ message: { body: message.body } })
        );
      }

      if (message.attachments && message.attachments.length) {
        message.attachments.forEach(async (uri) => {
          const attachment = await dispatch(createUpload(uri));

          messagesChannel.perform(
            'create',
            unformatObject({ message: { attachments: [attachment] } })
          );
        });
      }
    } catch (error) {
      dispatch(showGlobalError(error));
    }
  };
};

export const createConversationWithInitialMessage = async (
  dispatch,
  getState,
  message
) => {
  const user = getState().session.user;
  const conversation = {
    participants: [
      {
        id: user.id,
        name: user.name,
        patient: true,
        profileImage: user.profileImage,
      },
    ],
  };
  const conversationsChannel = Subscription.find({
    channel: 'ConversationsChannel',
  });
  if (!getState().message.isCreatingConversation) {
    await conversationsChannel.perform(
      'create',
      unformatObject({ conversation })
    );
    dispatch(receiveIsCreatingConversation(true));
  }

  let retryCount = 0;
  const conversationPoll = setInterval(() => {
    checkForConversation();
  }, 750);

  const checkForConversation = () => {
    if (retryCount > 30) {
      endPollingLoop(dispatch, conversationPoll);
    }

    const { conversationId } = getState().message;
    if (conversationId) {
      dispatch(createMessage(conversationId, message));
      endPollingLoop(dispatch, conversationPoll);
    }
    retryCount += 1;
  };
};

const endPollingLoop = (dispatch, conversationPoll) => {
  dispatch(receiveIsCreatingConversation(false));
  clearInterval(conversationPoll);
};

/**
 * Create a pending message in the UI while the WebSocket event is transmitted.
 *
 * @param  {integer}  conversationId  the conversation identifier
 * @param  {object}   message         an object describing the message
 */
export const createPendingMessage = (conversationId, message) => {
  return (dispatch, getState) => {
    dispatch(
      receiveMessage(conversationId, {
        ...message,
        id: Math.random(),
        author: getState().session.user,
        readBy: [getState().session.user.id],
        createdAt: new Date(),
        updatedAt: new Date(),
        _pending: true,
      })
    );
  };
};

/**
 * Disconnect from the ActionCable server and clear any references to the
 * subscriptions.
 *
 * @return  {promise}  a promise that resolves after disconnecting
 */
export const disconnectFromWebSocket = () => {
  return async (dispatch) => {
    retries = -1;
    clearTimeout(retryTimeout);

    await Subscription.disconnect();
    dispatch(receiveWebSocketConnected(false));
  };
};

/**
 * Fetch the conversations from the Messaging Service API.
 *
 * @return  {promise}  a promise that resolves with the current conversations
 */
export const fetchConversations = () => {
  return async (dispatch, getState) => {
    try {
      const { coreToken } = getState().session;

      const response = await axios.get(`${MESSAGE_API_URL}/conversations`, {
        headers: { Authorization: `Bearer ${coreToken}` },
      });

      if (response.data.data && response.data.data.length > 0) {
        dispatch(receiveConversations(response.data.data));
        dispatch(subscribeToMessages(response.data.data[0].id));
      }
    } catch (error) {
      dispatch(showGlobalError(error));
    }
  };
};

/**
 * Fetch messages for a conversation from the Messaging Service API.
 *
 * @return  {promise}  a promise that resolves with the current conversations
 */
export const fetchMessages = (conversationId, maxId) => {
  return async (dispatch, getState) => {
    try {
      const { coreToken } = getState().session;

      const response = await axios.get(
        `${MESSAGE_API_URL}/conversations/${conversationId}/messages`,
        {
          params: { max_id: maxId },
          headers: { Authorization: `Bearer ${coreToken}` },
        }
      );

      dispatch(receiveMessages(conversationId, response.data.data));
    } catch (error) {
      dispatch(showGlobalError(error));
    }
  };
};

/**
 * Fetch conversations and user status records.
 *
 * @return  {promise}  a promise that resolves after fetching the data
 */
export const fetchMessageData = () => {
  return async (dispatch) => {
    await Promise.all([
      dispatch(fetchUserStatuses()),
      dispatch(fetchConversations()),
    ]);
  };
};

/**
 * Fetch the user statuses from the Messaging Service API.
 *
 * @return  {promise}  a promise that resolves with the current user statuses
 */
export const fetchUserStatuses = () => {
  return async (dispatch, getState) => {
    try {
      const { coreToken } = getState().session;

      const response = await axios.get(`${MESSAGE_API_URL}/user_statuses`, {
        headers: { Authorization: `Bearer ${coreToken}` },
      });

      dispatch(receiveUserStatuses(response.data.data));
    } catch (error) {
      dispatch(showGlobalError(error));
    }
  };
};

/**
 * Mark the last unread message as read for a conversation.
 *
 * @param   {integer}  conversationId  the ID of the conversation to update
 *
 * @return  {promise}                  a promise that resolves after updating
 *                                     the last unread message
 */
export const markUnreadMessages = (conversationId) => {
  return (dispatch, getState) => {
    const { message, session } = getState();
    const conversation = message.conversations.find(
      ({ id }) => parseInt(id) === parseInt(conversationId)
    );
    const unreadMessages = conversation
      ? getUnreadMessages(conversation.messages, session.user.id)
      : [];
    if (!unreadMessages.length) return dispatch(setBadgeNumber(0));

    dispatch(incrementBadgeNumber(unreadMessages.length * -1));

    return dispatch(
      updateMessage(conversation.id, unreadMessages[0].id, { read: true })
    );
  };
};

/**
 * Attempt to retry connecting to ActionCable up to 30 times.
 *
 * @param  {object}  error  an Error object returned from the failed connection
 *                          attempt
 */
export const receiveConnectionError = (error) => {
  return (dispatch) => {
    dispatch(receiveWebSocketConnected(false));

    if (retries === -1) return;
    if (retries === 30)
      return logError(
        'Could not connect to ActionCable after 30 seconds',
        error
      );

    retries++;
    retryTimeout = setTimeout(() => dispatch(connectToWebSocket()), 1000);
  };
};

/**
 * Update the reducer state to indicate if the messages tab is open.
 *
 * @param   {boolean}  isOpen  whether the messages tab is open
 *
 * @return  {promise}          a promise that resolves after updating the
 *                             reducer state
 */
export const setMessagesOpen = (isOpen) => {
  return (dispatch) => {
    return Promise.resolve(dispatch(receiveMessagesOpen(isOpen)));
  };
};

/**
 * Display an incoming message as a pop-up, "Toast"-style notification. This
 * method is to be called when a user with an active conversation receives a
 * message and is on a page other than the Messages page.
 *
 * @param  {object}   data         the incoming message details
 * @param  {boolean}  autoDismiss  whether to automatically dismiss the
 *                                 notification
 */
export const showMessageAsLocalNotification = (data, autoDismiss = true) => {
  return (dispatch) => {
    let { author, body, notification, title } = data.attributes;
    title =
      title ||
      (notification ? 'Notification' : `New Message from ${author.name}`);

    if (body?.length > 140) body = body.slice(0, 140) + '...';

    if (title.length > 110) title = title.slice(0, 110) + '...';

    return dispatch(
      showLocalNotification({
        body,
        title,
        id: data.id,
        dismissIn: autoDismiss ? 5000 : null,
        icon: notification ? 'bell' : 'comment',
        redirect: '/messages',
      })
    );
  };
};

/**
 * Create a new subscription to the UserStatusChannel and begin streaming
 * updates when new statuses are received.
 *
 * @return  {promise}  a promise that resolves after connecting to the channel
 */
export const subscribeToUserStatuses = () => {
  return (dispatch, getState) => {
    return new Promise((resolve) => {
      const subscription = new Subscription(
        { channel: 'UserStatusChannel' },
        {
          connected: () => resolve(subscription),
          error: (error) =>
            logError('WebSocket error on UserStatusChannel', error),
          received: (data) => {
            // Don't proceed if session expired.
            if (!getState().session.coreToken) return;

            dispatch(receiveUserStatus(data.data));
          },
        }
      );
    });
  };
};

/**
 * Create a new subscription to the ConversationsChannel and begin streaming
 * updates when new conversations are received.
 *
 * @return  {promise}  a promise that resolves after connecting to the channel
 */
export const subscribeToConversations = () => {
  return (dispatch, getState) => {
    return new Promise((resolve) => {
      const subscription = new Subscription(
        { channel: 'ConversationsChannel' },
        {
          connected: () => resolve(subscription),
          error: (error) =>
            logError('WebSocket error on ConversationsChannel', error),
          received: (data) => {
            const { message, session } = getState();

            // Don't proceed if session expired.
            if (!session.coreToken) return;

            dispatch(receiveConversation(data.data));
            dispatch(subscribeToMessages(data.data.id));

            if (
              message.open ||
              session.user.notificationToken ||
              message.conversations.length !== 0
            )
              return;

            data.data.attributes.messages.forEach((attributes) => {
              dispatch(showMessageAsLocalNotification({ attributes }, false));
            });
          },
        }
      );
    });
  };
};

/**
 * Create a new subscription to the MessagesChannel for a given conversation
 * and begin streaming updates when new messages are received.
 *
 * @param   {integer}  conversationId  the identifier of a conversation to open
 *
 * @return  {promise}                  a promise that resolves after
 *                                     connecting to the channel
 */
export const subscribeToMessages = (conversationId) => {
  return (dispatch, getState) => {
    return new Promise((resolve) => {
      const subscription = new Subscription(
        { channel: 'MessagesChannel', conversation_id: conversationId },
        {
          error: (error) =>
            logError('WebSocket error on MessagesChannel', error),
          connected: () => {
            dispatch(receiveCurrentConversation(conversationId));
            resolve(subscription);
          },
          received: (data) => {
            const { message, session } = getState();

            // Don't proceed if session expired.
            if (!session.coreToken) return;

            dispatch(receiveMessage(conversationId, data.data));

            if (
              message.open ||
              session.user.notificationToken ||
              data.data.attributes.system
            )
              return;

            return dispatch(showMessageAsLocalNotification(data.data));
          },
        }
      );
    });
  };
};

/**
 * Subscribe to the required ActionCable channels.
 *
 * @return  {promise}  a promise that resolves after subscribing
 */
export const subscribeToChannels = () => {
  return async (dispatch) => {
    await Promise.all([
      dispatch(subscribeToUserStatuses()),
      dispatch(subscribeToConversations()),
    ]);

    dispatch(receiveWebSocketConnected(true));
  };
};

/**
 * Emit a WebSocket event to the MessagesChannel to indicate that a message has
 * been updated.
 *
 * @param  {integer}  conversationId  the conversation identifier
 * @param  {integer}  messageId       the message identifier
 * @param  {object}   message         an object describing the message
 */
export const updateMessage = (conversationId, messageId, message) => {
  return async (dispatch) => {
    try {
      let messagesChannel = Subscription.find({
        channel: 'MessagesChannel',
        conversation_id: conversationId,
      });

      if (!messagesChannel) {
        messagesChannel = await dispatch(subscribeToMessages(conversationId));
      }

      messagesChannel.perform(
        'update',
        unformatObject({ id: messageId, message })
      );
    } catch (error) {
      dispatch(showGlobalError(error));
    }
  };
};

export function receiveConversation(conversation) {
  return {
    type: RECEIVE_CONVERSATION,
    conversation,
  };
}

export function receiveCurrentConversation(conversationId) {
  return {
    type: RECEIVE_CURRENT_CONVERSATION,
    conversationId,
  };
}

export function receiveConversations(conversations) {
  return {
    type: RECEIVE_CONVERSATIONS,
    conversations,
  };
}

export function receiveMessage(conversationId, message) {
  return {
    type: RECEIVE_MESSAGE,
    conversationId,
    message,
  };
}

export function receiveMessages(conversationId, messages) {
  return {
    type: RECEIVE_MESSAGES,
    conversationId,
    messages,
  };
}

export function receiveMessagesOpen(isOpen) {
  return {
    type: RECEIVE_MESSAGES_OPEN,
    isOpen,
  };
}

export function receiveUserStatus(userStatus) {
  return {
    type: RECEIVE_USER_STATUS,
    userStatus,
  };
}

export function receiveUserStatuses(userStatuses) {
  return {
    type: RECEIVE_USER_STATUSES,
    userStatuses,
  };
}

export function receiveWebSocketConnected(isConnected) {
  return {
    type: RECEIVE_WEBSOCKET_CONNECTED,
    isConnected,
  };
}

export function receiveIsCreatingConversation(isCreatingConversation) {
  return {
    type: RECEIVE_IS_CREATING_CONVERSATION,
    isCreatingConversation,
  };
}
