import styled, { css } from 'styled-components/native';

import AnchorRaw, { AnchorProps } from 'app/components/Common/Anchor';

import {
  Text as TextRaw,
  View,
  ScrollView,
} from 'app/components/Common/Styled';

import ModalRaw from 'app/components/Common/Modal';

import { Divider as DividerRaw } from 'react-native-elements';
import { Platform } from 'react-native';

export { ModalOption } from 'app/components/Common/Modal';

export { default as TextArea } from 'app/components/Common/TextArea';

interface BodyTextProps {
  mb?: number;
  mt?: number;
}

const PlatformContainer = Platform.select({
  default: ScrollView as any,
  web: View,
});

export const Anchor = styled(AnchorRaw).attrs<AnchorProps>(({ theme }) => ({
  titleStyle: {
    textAlign: 'center',
    marginBottom: theme.spacing / 2,
    marginTop: theme.spacing / 2,
    fontSize: theme.fontSizes.body2,
    fontFamily: theme.fontFamily,
  },
}))<AnchorProps>``;

export const BodyText = styled(TextRaw).attrs({
  size: 'body2',
})<BodyTextProps>`
  font-family: ${({ theme }) => theme.fontFamily};
  margin-top: ${({ mt = 0, theme }) => theme.spacing * mt}px;
`;

export const Container = styled(PlatformContainer)``;

export const Divider = styled(DividerRaw)`
  margin-top: ${({ theme }) =>
    theme.isNarrow ? `${theme.spacing / 2}px` : `${theme.spacing}px`};
`;

export const HeaderText = styled(TextRaw)`
  ${({ theme }) => css`
    font-size: ${theme.fontSizes.body1}px;
    font-weight: bold;
    margin-top: ${theme.spacing / 2}px;
    margin-bottom: ${theme.spacing / 2}px;
  `}
`;

export const Modal = styled(ModalRaw).attrs(({ theme }) => ({
  overlayStyleOverrides: {
    backgroundColor: theme.colors.white,
    minHeight: 0,
    maxHeight: theme.windowDimensions.height - theme.spacing * 6.5,
  },
}))``;
