import { callCarrum } from 'app/util/call';

import {
  Anchor,
  BodyText,
  Container,
  Divider,
  HeaderText,
  Modal,
} from './styles';

import { HolidayModalProps } from 'app/components/Messages/types';

export function HolidayModal({ onClose, visible }: HolidayModalProps) {
  return (
    <Modal
      isVisible={visible}
      onClose={onClose}
      title="Holiday Notice"
      size="medium"
    >
      <Container disableScrollViewPanResponder>
        <>
          <Divider />
          <BodyText mt={2.5}>
            Our offices will be closed from July 4th through July 5th, reopening
            on July 8th.
          </BodyText>
          <BodyText mt={2.5}>
            For any urgent needs, we encourage you to contact our main line at{' '}
            <Anchor onPress={(e) => callCarrum(e)} title="1-888-855-7806" />. We
            appreciate your patience and understanding.
          </BodyText>
        </>
      </Container>
    </Modal>
  );
}
