import { getMessageTextWrapperStyleParams } from 'app/components/Messages/helpers';

import {
  Anchor,
  AttachmentPreview,
  Linking,
  MessageGroupMessageContainer,
  MessageText,
  MessageTextWrapper,
} from 'app/components/Messages/styles';

import { MessageGroupMessageProps } from 'app/components/Messages/types';

/**
 * Render a single message within a message group.
 *
 * @param  {array}     attachments      an array of attachments for the message
 * @param  {string}    body             the message text
 * @param  {integer}   count            the number of messages in this group
 * @param  {integer}   index            the index of this message in the group
 * @param  {boolean}   patientIsAuthor  true when current user is the author
 * @param  {boolean}   read             true when current user read the message
 */
const Message = ({
  attachments = [],
  body = '',
  count = 0,
  index = 0,
  patientIsAuthor = false,
  read = false,
}: MessageGroupMessageProps) => (
  <MessageGroupMessageContainer>
    {body && body.length ? (
      <MessageTextWrapper
        {...getMessageTextWrapperStyleParams(index, count, patientIsAuthor)}
        patientIsAuthor={patientIsAuthor}
      >
        <MessageText patientIsAuthor={patientIsAuthor} isRead={read}>
          <Anchor
            patientIsAuthor={patientIsAuthor}
            stripPrefix={false}
            text={body.replace(/<(|\/)\w+>/g, '')}
            truncate={42}
            truncateLocation="end"
          />
        </MessageText>
      </MessageTextWrapper>
    ) : null}

    {attachments.map((attachment, index) => (
      <AttachmentPreview
        file={attachment}
        key={index}
        onPress={() => Linking.openURL(attachment)}
        patientIsAuthor={patientIsAuthor}
      />
    ))}
  </MessageGroupMessageContainer>
);

export default Message;
