import theme from 'app/util/theme';

export const getWidth = (modalSize, screenWidth): number | string => {
  switch (true) {
    case modalSize === 'small' && screenWidth < theme.breakpoints.xsmall:
      return '100%';
    case modalSize === 'small':
      return theme.spacing * 25; // 400: magic number, prescribed by Aimee/Design
    case modalSize === 'medium' && screenWidth < theme.breakpoints.small:
      return '100%';
    case modalSize === 'medium':
      return theme.spacing * 43; // 688: magic number, prescribed by Aimee/Design
    case modalSize === 'large' && screenWidth < theme.breakpoints.small:
      return '100%';
    case modalSize === 'large':
      return theme.spacing * 48; // 768: magic number, prescribed by Aimee/Design
    case modalSize === 'xlarge' && screenWidth < theme.breakpoints.medium:
      return '100%';
    case modalSize === 'xlarge':
      return theme.spacing * 55; // 880: magic number, prescribed by Aimee/Design
  }
};

// Export intended only for positioning the Close X (CloseIconWrapper)
export const getPadding = (modalSize, screenWidth): number => {
  switch (true) {
    case modalSize === 'small':
      return theme.spacing * 1.25;
    case modalSize === 'medium' && screenWidth < theme.breakpoints.small:
      return theme.spacing * 1.25;
    case modalSize === 'medium':
      return theme.spacing * 3.5; // Yes, padding for "medium" size modals is larger than "large" size modals
    case modalSize === 'large':
      return theme.spacing * 1.25; // Bug fix for Message Modal close button
    case modalSize === 'xlarge' && screenWidth < theme.breakpoints.small:
      return theme.spacing * 1.25;
    case modalSize === 'xlarge':
      return theme.spacing * 2.5;
  }
};
