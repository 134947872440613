// @ts-expect-error Cannot find module 'app/assets/images/anonymous.jpg'
export { default as defaultAuthorProfileImage } from 'app/assets/images/anonymous.jpg';

export const initialFormValues = { body: '', attachments: [] };

export { EVENTS } from 'app/util/analytics';

export const messagesPerPage = 20;

export { TestID } from 'app/util/test-id';

export { default as theme } from 'app/util/theme';
