import { TestID } from 'app/components/Messages/constants';

import {
  NotificationGroupAvatar,
  NotificationGroupContainer,
  NotificationGroupContent,
  NotificationGroupTimestamp,
  NotificationMessage,
  View,
} from 'app/components/Messages/styles';

import { NotificationGroupProps } from 'app/components/Messages/types';

/**
 * Render a group of notification messages.
 *
 * @param  {string}  timestamp  when the first message in the group was created
 * @param  {array}   messages   group of message objects
 */
export default function NotificationGroup({
  timestamp,
  messages,
}: NotificationGroupProps) {
  const iconName =
    messages[0].notificationType === 'outside_business_hours'
      ? 'clock-o'
      : 'bell';

  return (
    <NotificationGroupContainer testID={TestID.Messages.NotificationGroup}>
      <NotificationGroupAvatar iconName={iconName} />

      <NotificationGroupContent>
        <NotificationGroupTimestamp>{timestamp}</NotificationGroupTimestamp>

        <View>
          {messages.map(({ id, body }) => (
            <NotificationMessage key={id} body={body} />
          ))}
        </View>
      </NotificationGroupContent>
    </NotificationGroupContainer>
  );
}
