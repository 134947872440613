import {
  receiveSetCallUsModalVisibility,
  routeToFAQ,
} from 'app/components/ProcedureSearch/helpers';

import { useDispatch } from 'app/components/ProcedureSearch/hooks';

import { ProcedureSearchPageFooterStyles as Styled } from 'app/components/ProcedureSearch/styles';

const ProcedureSearchPageFooter = ({
  queryHasNoResults,
  setIsLetUsKnowVisible,
  isMediumOrLargerDevice,
}: {
  isMediumOrLargerDevice: boolean;
  queryHasNoResults: boolean;
  setIsLetUsKnowVisible: any;
}) => {
  const dispatch = useDispatch();

  return (
    <Styled.Wrapper>
      {queryHasNoResults && (
        <Styled.NoResults.Wrapper>
          <Styled.NoResults.Message>
            Can&apos;t find what you&apos;re looking for?
          </Styled.NoResults.Message>

          <Styled.NoResults.CtaButton
            title="Let us know"
            onPress={() => setIsLetUsKnowVisible(true)}
            type="clear"
          />
        </Styled.NoResults.Wrapper>
      )}

      <Styled.Icons.Wrapper>
        <Styled.Icons.About.Wrapper>
          <Styled.Icons.About.Icon
            title={isMediumOrLargerDevice ? 'About Carrum' : 'About'}
            iconName="info"
            iconType="Material"
            onPress={routeToFAQ}
          />
        </Styled.Icons.About.Wrapper>

        <Styled.Icons.Chat.Wrapper>
          <Styled.Icons.Chat.Icon
            title={isMediumOrLargerDevice ? 'Message Us' : 'Chat'}
            iconName="commenting"
            iconType="FontAwesome"
            onPress={() => setIsLetUsKnowVisible(true)}
          />
        </Styled.Icons.Chat.Wrapper>

        <Styled.Icons.Phone.Wrapper>
          <Styled.Icons.Phone.Icon
            title="Talk To Us"
            iconName="phone"
            iconType="FontAwesome"
            onPress={() => {
              dispatch(receiveSetCallUsModalVisibility(true));
            }}
          />
        </Styled.Icons.Phone.Wrapper>
      </Styled.Icons.Wrapper>
    </Styled.Wrapper>
  );
};

export default ProcedureSearchPageFooter;
