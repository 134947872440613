import {
  NotificationMessageContainer,
  NotificationMessageText,
} from 'app/components/Messages/styles';

import { NotificationMessageProps } from 'app/components/Messages/types';

/**
 * Render a notification message.
 *
 * @param  {string}  body  content for the message
 */
export default function NotificationMessage({
  body,
}: NotificationMessageProps) {
  return (
    <NotificationMessageContainer>
      <NotificationMessageText>{body}</NotificationMessageText>
    </NotificationMessageContainer>
  );
}
